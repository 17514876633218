<h1 mat-dialog-title>{{ lang.createWarning.warningSent }}</h1>
<div class="content" mat-dialog-content>
  <p>
    {{ lang.createWarning.warningLink }}
    <a href="{{warningLink}}" mat-stroked-button target="_blank">
      {{ lang.createWarning.warningDetail }}
    </a>
  </p>
</div>
<div mat-dialog-actions>
  <button mat-dialog-close mat-stroked-button>
    {{ lang.createWarning.close }}
  </button>
</div>

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, rootEffectsInit } from '@ngrx/effects';
import {
  loginFailureAction,
  loginFromLastSessionAction,
  loginRequestAction,
  loginSuccessAction,
  logoutFailureAction,
  logoutRequestAction,
  logoutSuccessAction,
} from '../actions/authentication.actions';
import {
  catchError,
  delayWhen,
  filter,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { of, timer } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { FIREBASE_NEW_USER_DELAY, RoutePath } from '../../app.constants';

@Injectable()
export class AuthenticationEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly auth: AuthService,
    private readonly router: Router,
  ) {}
  storeDataFromLastSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rootEffectsInit),
      switchMap(() => this.auth.getRedirectResult),
      delayWhen(user =>
        timer(
          user.additionalUserInfo && user.additionalUserInfo.isNewUser
            ? FIREBASE_NEW_USER_DELAY
            : 0,
        ),
      ),
      switchMap(user => {
        if (user.additionalUserInfo && user.additionalUserInfo.isNewUser) {
          return this.auth.fireAuth.currentUser.then(usr =>
            usr.getIdToken(true),
          );
        }
        return of(null);
      }),
      switchMap(() =>
        this.auth.fireAuth.idTokenResult.pipe(
          take(1),
          filter(user => !!user),
          map(user => loginFromLastSessionAction({ userInfo: user })),
        ),
      ),
    ),
  );

  loginEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginRequestAction),
      switchMap(() =>
        this.auth.googleLogin().pipe(
          switchMap(() => this.auth.getRedirectResult),
          map(({ user }) =>
            loginSuccessAction({ userInfo: Object.create(user) }),
          ),
          tap(() => this.router.navigate([RoutePath.Platform])),
          catchError(error => of(loginFailureAction({ error }))),
        ),
      ),
    ),
  );

  logoutEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logoutRequestAction),
      switchMap(() =>
        this.auth.logout().pipe(
          map(() => logoutSuccessAction()),
          tap(() => this.router.navigate([RoutePath.Landing])),
          catchError(error => of(logoutFailureAction({ error }))),
        ),
      ),
    ),
  );
}

import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, delay, map, switchMap, tap } from 'rxjs/operators';
import { APIClient } from '../../../api';
import { of } from 'rxjs';
import { Translation, TRANSLATION } from '../../i18n/utils';
import {
  deleteTemplateFailureAction,
  deleteTemplateRequestAction,
  deleteTemplateSuccessAction,
  getTemplateDetailFailureAction,
  getTemplateDetailRequestAction,
  getTemplateDetailSuccessAction,
  patchTemplateFailureAction,
  patchTemplateRequestAction,
  patchTemplateSuccessAction,
  postTemplateFailureAction,
  postTemplateRequestAction,
  postTemplateSuccessAction,
} from '../actions/templates.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getIntegrationRequestAction } from '../actions/integrations.actions';
import { redirectToIntegrationDetailAction } from '../actions/route.actions';
import { SNACKBAR_DELAY } from '../../app.constants';

@Injectable()
export class TemplatesEffects {
  getTemplateDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getTemplateDetailRequestAction),
      switchMap(({ id }) =>
        this.api.getTemplateDetail({ id }).pipe(
          map(template => getTemplateDetailSuccessAction({ template })),
          catchError(error => of(getTemplateDetailFailureAction({ error }))),
        ),
      ),
    ),
  );

  patchTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patchTemplateRequestAction),
      switchMap(({ template }) =>
        this.api.patchTemplate({ id: template.id, body: template }).pipe(
          map(tmp => patchTemplateSuccessAction({ template: tmp })),
          catchError(error => of(patchTemplateFailureAction({ error }))),
        ),
      ),
    ),
  );

  addTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postTemplateRequestAction),
      switchMap(({ template }) =>
        this.api
          .createTemplate({
            body: template,
          })
          .pipe(
            map(newTemplate =>
              postTemplateSuccessAction({ template: newTemplate }),
            ),
            catchError(error => of(postTemplateFailureAction({ error }))),
          ),
      ),
    ),
  );

  deleteTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteTemplateRequestAction),
      switchMap(({ templateId, integrationId }) =>
        this.api
          .deleteTemplate({
            id: templateId,
          })
          .pipe(
            map(() => deleteTemplateSuccessAction({ integrationId })),
            catchError(error => of(deleteTemplateFailureAction({ error }))),
          ),
      ),
    ),
  );

  templateDeleteSuccessSnackBar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteTemplateSuccessAction),
        tap(() => {
          this.snackBar.open(
            this.lang.templates.deleteSuccess,
            this.lang.common.close,
            { duration: SNACKBAR_DELAY },
          );
        }),
      ),
    { dispatch: false },
  );

  templateCreatedSuccessSnackBar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(postTemplateSuccessAction),
        tap(() => {
          this.snackBar.open(
            this.lang.templates.createSuccess,
            this.lang.common.close,
            { duration: SNACKBAR_DELAY },
          );
        }),
      ),
    { dispatch: false },
  );

  createTemplateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postTemplateSuccessAction),
      delay(SNACKBAR_DELAY),
      map(({ template }) =>
        redirectToIntegrationDetailAction({
          integrationId: template.integrationId,
        }),
      ),
    ),
  );

  deleteTemplateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteTemplateSuccessAction),
      map(({ integrationId }) =>
        getIntegrationRequestAction({ integrationId, templates: true }),
      ),
    ),
  );

  templatePatchSuccessSnackBar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(patchTemplateSuccessAction),
        tap(() => {
          this.snackBar.open(
            this.lang.templates.patchSuccess,
            this.lang.common.close,
            { duration: SNACKBAR_DELAY },
          );
        }),
      ),
    { dispatch: false },
  );

  constructor(
    @Inject(TRANSLATION) public readonly lang: Translation,
    private readonly actions$: Actions,
    private readonly api: APIClient,
    private readonly snackBar: MatSnackBar,
  ) {}
}

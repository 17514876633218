import { AppState } from '../reducers';
import { createSelector } from '@ngrx/store';
import { RouteState } from '../reducers/route.reducer';

const $routeSlice = ({ route }: AppState): RouteState => route;

export const $navigationFinished = createSelector(
  $routeSlice,
  ({ navigationFinished }): boolean => navigationFinished,
);

export const $currentLanguage = createSelector(
  $routeSlice,
  ({ pathSegments }): string => pathSegments[0] || '',
);

export const $selectedClientId = createSelector(
  $routeSlice,
  ({ params }): string => params['userId'],
);
export const $selectedPartnerId = createSelector(
  $routeSlice,
  ({ params }): string => params['partnerId'],
);
export const $selectedWarningId = createSelector(
  $routeSlice,
  ({ params }): string => params['warningId'],
);

export const $selectedIntegrationId = createSelector(
  $routeSlice,
  ({ params }): string => params['integrationId'],
);

export const $selectedTemplateId = createSelector(
  $routeSlice,
  ({ params }): string => params['templateId'],
);

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import {
  getWarningsRequestAction,
  getWarningsSuccessAction,
} from '../actions/warnings.actions';
import { V2Warning } from '../../../api/models';
import { from } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { $lastWarning } from '../selectors/warnings.selectors';
import { WARNINGS_PER_REQUEST } from '../../app.constants';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable()
export class WarningsEffects {
  private readonly lastWarning$ = this.store.pipe(select($lastWarning));

  getWarnings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getWarningsRequestAction),
      map(({ filters }) => ({
        collection: this.firestore
          .collection('warnings')
          .ref.orderBy('createdAt', 'desc'),
        filters,
      })),
      map(({ collection, filters }) => ({
        collection: filters.country
          ? collection.where('country', '==', filters.country)
          : collection,
        filters,
      })),
      map(({ collection, filters }) => ({
        collection: filters.status
          ? collection.where('status', '==', filters.status)
          : collection,
        filters,
      })),
      map(({ collection, filters }) => ({
        collection: filters.type
          ? collection.where('type', '==', filters.type.trim())
          : collection,
        filters,
      })),
      map(({ collection, filters }) => ({
        collection: filters.userName
          ? collection.where('userName', '==', filters.userName.trim())
          : collection,
        filters,
      })),
      withLatestFrom(this.lastWarning$),

      map(([{ collection, filters }, latestWarning]) => ({
        collection:
          !filters.clearPreviousResults && latestWarning != null
            ? collection.startAfter(latestWarning)
            : collection,
        filters,
      })),
      switchMap(({ collection, filters }) =>
        from(collection.limit(WARNINGS_PER_REQUEST).get()).pipe(
          map(querySnapshot =>
            getWarningsSuccessAction({
              warnings: querySnapshot.docs.map(doc => doc.data() as V2Warning),
              lastWarningRef: querySnapshot.docs.length
                ? querySnapshot.docs[querySnapshot.docs.length - 1].data()[
                    'createdAt'
                  ]
                : null,
              clearPreviousResults: filters.clearPreviousResults,
            }),
          ),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly firestore: AngularFirestore,
    private readonly store: Store<AppState>,
  ) {}
}

import { Component, Inject, OnInit } from '@angular/core';
import {
  Translation,
  TRANSLATION,
  WebsiteLanguage,
} from '../../../../../i18n/utils';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { V2CreateWarningResponse } from '../../../../../../api/models';

@Component({
  selector: 'met-warning-sent-dialog',
  templateUrl: 'warning-sent.html',
  styles: ['.content {padding: 1rem}'],
})
export class WarningSentComponent implements OnInit {
  warningLink = '';

  constructor(
    @Inject(TRANSLATION) public readonly lang: Translation,
    @Inject(MAT_DIALOG_DATA)
    public dryRunResult: {
      result: V2CreateWarningResponse;
    },
  ) {}

  ngOnInit(): void {
    this.warningLink = `/${WebsiteLanguage.Czech}/platform/warning-detail?warningId=${this.dryRunResult.result.id}`;
  }
}

import { createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
  getActualLocationsFailureAction,
  getActualLocationsRequestAction,
  getActualLocationsSuccessAction,
  removeLocationsAction,
} from '../actions/actualLocations.actions';

export interface ClientLocationsState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  locations: any[];
  loading: boolean;
}

const initialState: ClientLocationsState = {
  locations: [],
  loading: false,
};

export const situationAdapter = createEntityAdapter<ClientLocationsState>();

const reducer = createReducer(
  initialState,
  on(getActualLocationsRequestAction, state => ({
    ...state,
    locations: undefined,
    loading: true,
  })),
  on(getActualLocationsSuccessAction, (state, { locations }) => ({
    ...state,
    locations: [...locations.actualLocations],
    loading: false,
  })),
  on(getActualLocationsFailureAction, state => ({
    ...state,
    loading: false,
  })),
  on(removeLocationsAction, state => ({
    ...state,
    locations: undefined,
    loading: false,
  })),
);

export function clientLocationsReducer(
  state: ClientLocationsState,
  action: Action,
): ClientLocationsState {
  return reducer(state, action);
}

export const environment = {
  production: false,
  demo: true,
  beURL: 'https://demo.api.swaps.meteopress.cz',
  firebase: {
    apiKey: 'AIzaSyAqfndW5T5-2kyT3A7NoMT_Gms4bb--KPE',
    authDomain: 'meteopress-swaps-demo.firebaseapp.com',
    databaseURL: 'https://meteopress-swaps-demo.firebaseio.com',
    projectId: 'meteopress-swaps-demo',
    storageBucket: 'meteopress-swaps-demo.appspot.com',
    messagingSenderId: '545572105410',
    appId: '1:545572105410:web:d8933e62ed0590c29b1f5b',
  },
};

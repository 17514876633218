import { Action, createReducer, on } from '@ngrx/store';
import {
  navigationEndNotifyAction,
  navigationStartNotifyAction,
} from '../actions/route.actions';
import { RouteModel } from '../../models/route.model';

export interface RouteState extends RouteModel {
  navigationFinished: boolean;
  pathSegments: string[];
}

const initialState: RouteState = {
  navigationFinished: true,
  pathSegments: [],
  params: {},
};

const reducer = createReducer(
  initialState,
  on(navigationStartNotifyAction, state => ({
    ...state,
    navigationFinished: false,
  })),
  on(
    navigationEndNotifyAction,
    (state, { route: { pathSegments, params } }) => ({
      ...state,
      pathSegments,
      params,
      navigationFinished: true,
    }),
  ),
);

export function routeReducer(
  state: RouteState | undefined,
  action: Action,
): RouteState {
  return reducer(state, action);
}

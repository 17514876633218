export const cs = {
  language: 'Česky',
  languageId: 'cs',
  locale: 'cs-CZ',
  navigationBar: {
    createWarning: 'Mapa',
    clients: 'Klienti',
    partners: 'Partneři',
    warningList: 'Historie varování',
    statistics: 'Statistiky',
    invoicing: 'Fakturace',
    logout: 'Odhlásit',
    users: 'Uživatelé',
    templates: 'Šablony',
  },
  common: {
    uidError: 'UID musí obsahovat pouze čísla',
    zipError: 'Je nutné zadat platné PSČ',
    emailError: 'Musí být zadán platný e-mail',
    phoneError: 'Musí být zadáno platné telefonní číslo',
    requiredError: 'Tato položka je povinná',
    notRequired: 'Tato položka je nepovinná',
    elevationError: 'Nadmořská výška musí být číslo',
    templateEmpty: 'Zvolte typ varování',
    value: 'Hodnota',
    cancel: 'Zrušit',
    cancelWarning: 'Zrušit varování',
    confirm: 'Potvrdit',
    givenName: 'Jméno',
    surname: 'Příjmení',
    email: 'E-mail',
    phoneNumber: 'Telefonní číslo',
    delete: 'Smazat',
    deletedAt: 'Smazáno',
    noResults: 'Žádné výsledky',
    resetFilters: 'Reset',
    filter: 'Filtrovat',
    filters: 'Filtry',
    true: 'Ano',
    false: 'Ne',
    uid: 'UID',
    id: 'ID',
    czechia: 'Česko',
    slovakia: 'Slovensko',
    send: 'Odeslat',
    sendWarning: 'Odeslat varování',
    save: 'Uložit',
    loadMore: 'Načíst další',
    error: 'Vyskytla se chyba',
    close: 'Zavřít',
    createdAt: 'Datum vytvoření',
    role: 'Role',
    edit: 'Editovat',
    saveSuccess: 'Úspěšně uloženo',
  },
  integrations: {
    active: 'Aktivní',
    type: 'Typ',
    country: 'Země',
    value: 'Hodnota',
    test: 'Test',
    integrations: 'Integrace',
    yes: 'Ano',
    no: 'Ne',
    templates: 'Šablony',
    partnerId: 'Id Partnera',
  },
  createWarning: {
    basicMap: 'Základní',
    geologicalMap: 'Zeměpisná',
    czSkMap: 'CZ/SK',
    country: 'Země',
    fromElevation: 'Od nadmořské výšky',
    template: 'Typ varování',
    warningType: 'Typ varování',
    notNotifyReported:
      'Neposílat klientům, kteří byli upozorněni v předchozím hlášení.',
    testTitle: 'Testovací varování',
    testSubtitle: 'Odeslat pouze testovacím klientům',
    chooseDate: 'Zvolte datum',
    dateFrom: 'Datum od',
    dateTo: 'Datum do',
    dateFromError: 'Datum OD musí být v budoucnosti',
    dateToError: 'Datum DO musí být po datu OD',
    draw: 'Editovat varování',
    erase: 'Mazat varování',
    range: 'Velikost pera',
    eraseAll: 'Smazat vše',
    stop: 'Ukončit editaci',
    move: 'Posun po mapě',
    lock: 'Zamknout mapu',
    unlock: 'Odemknout mapu',
    noMapDataTitle: 'Žádná vyznačená oblast',
    noMapData:
      'Pro odeslání varování musí být na mapě vyznačena nějaká oblast.',
    close: 'Zavřít',
    removeLayer: 'Odstranit vrstvu',
    wind: 'Vítr',
    temperature: 'Teplota',
    precipitation: 'Srážky',
    snow: 'Sníh',
    opacity: 'Průhlednost',
    layers: 'Vrstvy',
    mask: 'Maska',
    nextImage: 'Další obrázek',
    previousImage: 'Předešlý obrázek',
    toggleRadar: 'Zapnout/vypnout radar',
    toggleMask: 'Zapnout/vypnout masku',
    rough: 'Hrubý',
    prediction: 'Předpověď',
    detailed: 'Detailní',
    undo: 'Zpět',
    redo: 'Vpřed',
    mapMenu: 'Mapa',
    noLayer: 'Žádná vrstva',
    brushSize: 'Velikost štětce',
    goThroughLayer: 'Procházení vrstvy',
    radar: 'Radar',
    confirmWarning: 'Chci odeslat',
    warningLayers: 'Varování',
    toggleWarningLayers: 'Zapnout/vypnout Aktuálni varování',
    from: 'Od',
    to: 'Do',
    warningSent: 'Varování odesláno',
    warningLink: 'Zde se můžete podívat na detail odeslaného varování:',
    warningDetail: 'Detail varování',
    warningDurationTooLong: 'Varováni má platnost delší než 72 hodin',
    warningStartsTooLate: 'Varování začíná za více než 48 hodin',
    warningWrongDate: 'Datum je ve špatném formátu',
    warningShort: 'Minimální délka varování je 10 minut',
    fieldRequired: 'Tato položka musí být vyplněna',
    warningError: 'Vyskytla se chyba při vytváření varování',
    error: 'Nastal problém',
    warningTestClients: 'Varování bude odesláno pouze testovacím klientům',
    warningTestEnv: 'Varování bude odesláno klientům v testovacím prostředí',
    warningSimulation: 'Toto varování je simulace a nebude nikomu odesláno',
    warningRealClients: 'Varování bude odesláno skutečným klientům',
    actualTime: 'Aktuálně',
    testLocality: 'Testovací lokalita',
  },
  warningDetail: {
    total: 'Celkem',
    sent: 'Odesláno',
    failed: 'Neodesláno',
    sms: 'SMS',
    fcm: 'FCM',
    integrations: 'Integrace',
    mails: 'Maily',
    author: 'Vydal',
    template: 'Šablona',
    timeFrom: 'Čas od',
    timeTo: 'Čas do',
    createdAt: 'Čas vydání',
    sendingAt: 'Začátek odesílaní',
    updatedAt: 'Čas poslední zprávy',
    clientCount: 'Klientů',
    zipCount: 'PSČ',
    values: 'Hodnoty',
    status: 'Stav varování',
    test: 'Testovací varování',
    yes: 'Ano',
    fromElevation: 'Od nadmořské výšky',
    mnm: 'm n. m.',
  },
  landing: {
    swaps: 'SWAPS',
    meteopress: '--METEOPRESS--',
    login: 'Přihlásit',
  },
  clientList: {
    partner: 'Partner',
    test: 'Testovací',
    createClient: 'Vložit klienta',
    createdAt: 'Datum vytvoření',
    deletedAt: 'Datum smazání',
    includeDeleted: 'Zobrazit smazané',
  },
  clientDetail: {
    contactsTitle: 'Kontakty',
    client: 'Klient',
    emailTitle: 'E-mailové adresy',
    addEmail: 'Přidat e-mailovou adresu',
    email: 'Adresa',
    phoneNumbersTitle: 'Telefonní čísla',
    addPhone: 'Přidat telefonní číslo',
    number: 'Číslo',
    locationsTitle: 'Lokace',
    actualLocationsTitle: 'Aktuální lokace',
    addLocation: 'Přidat polohu',
    saveSuccessTitle: 'Úspěšně uloženo',
    saveSuccessDescription: 'Klient byl úspěšně uložen',
    zips: 'Oblast',
    locations: 'míst',
    location: {
      country: 'Země',
      language: 'Jazyk',
      zip: 'PSČ',
      lat: 'Zeměpisná šířka',
      lng: 'Zeměpisná délka',
      radius: 'Rádius',
      elevation: 'Nadmořská výška',
      mnm: 'm n.m.',
    },
    note: 'Poznámka',
    delete: 'Smazat klienta',
  },
  partnerList: {
    note: 'Poznámka',
    sendWarnings: 'Odesílat varování',
    createPartner: 'Vložit partnera',
  },
  partnerDetail: {
    partner: 'Partner',
    saveSuccessTitle: 'Úspěšně uloženo',
    saveSuccessDescription: 'Partner byl úspěšně uložen',
    delete: 'Smazat partnera',
  },
  warningList: {
    createdAt: 'Vytvořeno',
    country: 'Stát',
    type: 'Šablona',
    value: 'Hodnota',
    userName: 'Autor',
    status: 'Status',
    detail: 'Detail',
    filter: 'Filtrovat',
    test: 'Test',
    yes: 'Ano',
  },
  templates: {
    subject: 'Předmět',
    type: 'Typ šablony',
    warningType: 'Typ varování',
    id: 'ID',
    partner: 'Partner',
    templateDetail: 'Detail šablony',
    template: 'Šablona',
    iframePreview: 'Náhled v iFrame',
    previewHtml: 'Náhled HTML',
    patchSuccess: 'Šablona úspěšně uložena',
    createTemplate: 'Vytvořit šablonu',
    noValues: 'žádné',
    values: 'Hodnoty',
    delete: 'Smazat',
    deleteConfirmation: 'Opravdu si přejete smazat šablonu',
    deleteSuccess: 'Šablona úspěšně smazána',
    createSuccess: 'Šablona úspěšně vytvořena',
  },
  statistics: {
    /* internally we call client statistics - partner statistics */
    partners: 'Klienti',
    clients: 'Klienti',
    warning: 'Varování',
    created: 'Vytvořeno',
    notifications: 'Notifikace',
    day: 'Den',
    clientCount: 'Počet klientů',
    createdCount: 'Počet vytvořených',
    notificationCount: 'Počet notifikací',
  },
  invoicing: {
    partner: 'Partner',
    nothingToDisplay: 'Není co zobrazit',
    monthStart: 'Datum',
    warningsCount: 'Vydaných varování',
    clientsCountStart: 'Klientů na začátku měsíce',
    clientsCountEnd: 'Klientů na konci měsíce',
    clientsAdded: 'Smazáno klientů',
    clientsDeleted: 'Přidáno klientů',
    warningType: 'Typ varování',
    warningDate: 'Datum varování',
    country: 'Země',
    smsCount: 'Odeslaných sms',
    emailsCount: 'Odeslaných e-mailů',
    clients: 'Kontaktovaných klientů',
  },
  platform: {},
  modals: {
    clientInsertion: {
      title: 'Vložit klienta',
      partner: 'Zvolte partnera',
      email: 'E-mail',
      emailTitle: 'Přidat e-mailovou adresu',
      phone: 'Telefonní číslo',
      phoneTitle: 'Přidat telefonní číslo',
      locaton: 'Poloha',
      locationTitle: 'Přidat polohu',
      yes: 'Ano',
      no: 'Ne',
      testClient: 'Testovací klient',
      disableLastNotifiedCheck: 'Vynutit zaslání překrývajících se varování',
      test: 'Testovací klient',
    },
    doubleCheckOrDie: {
      title: 'Odeslat varování',
      template: 'Typ varování',
      messageText: 'Text zprávy',
      from: 'Platné od',
      to: 'Platné do',
      fromElevation: 'Od nadmořské výšky',
      notifiedZip: `Varování zasáhne`,
      mapTitle: 'Zvolená oblast',
      zipCount: (count: number) => `${count} PSČ`,
      notifiedClients: 'Varování zasáhne',
      clientsCount: (count: number) => {
        switch (count) {
          /* eslint-disable no-magic-numbers */
          case 1:
            return `${count} klienta`;
          case 2:
          case 3:
          case 4:
            return `${count} klienty`;
          /* eslint-enable no-magic-numbers */
          default:
            return `${count} klientů`;
        }
      },
      warningDurationTooLong: 'Varováni má platnost delší než 72 hodin',
      warningStartsTooLate: 'Varování začíná za více než 48 hodin',
      warningStartsIn: 'Varování začne za',
      warningDuration: 'Varování bude platné po dobu',
      warningInPastWarn: 'Varování musí být v budoucnu.',
    },
    partnerInsertion: {
      title: 'Vložit partnera',
      name: 'Název',
      note: 'Poznámka',
      smsNo: 'ID SMS',
      emailFrom: 'Adresa odesílatele e-mailů',
      emailFromNamePlaceholder: 'Nahrazuje název partnera',
      emailFromName: 'Jméno odesílatele e-mailů',
    },
    deletionConfirm: {
      message: (type: string, value: string) =>
        `Skutečně si přejete odstranit ${type} ${value}?`,
      type: {
        email: 'e-mailovou adresu',
        phone: 'telefonní číslo',
        location: 'adresu',
        client: 'klienta',
        partner: 'partnera',
      },
    },
  },
  unauthorized: {
    message: 'Nemáte oprávnění pracovat se systémem.',
  },
};

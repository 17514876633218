import { Action, createReducer, on } from '@ngrx/store';
import { getMapLayerSuccessAction } from '../actions/layers.actions';

export interface LayersState {
  mapLayerData: {} | null;
}

const initialState: LayersState = {
  mapLayerData: {},
};

const reducer = createReducer(
  initialState,
  on(getMapLayerSuccessAction, (state, { layer, name, time, country }) => ({
    ...state,
    mapLayerData: {
      ...state.mapLayerData,
      [name + time + country]: {
        data: layer,
        name: name,
        time: time,
        country: country,
      },
    },
  })),
);

export function layersReducer(state: LayersState, action: Action): LayersState {
  return reducer(state, action);
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import {
  getMapLayerAction,
  getMapLayerFailureAction,
  getMapLayerSuccessAction,
} from '../actions/layers.actions';

@Injectable()
export class LayersEffects {
  loadMapLayer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMapLayerAction),
      mergeMap(({ url, name, time, country }) => {
        const data$ = new Observable(observer => {
          fetch(url)
            .then(response => response.json()) // or text() or blob() etc.
            .then(data => {
              observer.next(data);
              observer.complete();
            })
            .catch(err => observer.error(err));
        });
        return data$.pipe(
          map(layer =>
            getMapLayerSuccessAction({ layer, name, time, country }),
          ),
          catchError(error => of(getMapLayerFailureAction({ error }))),
        );
      }),
    ),
  );

  constructor(private readonly actions$: Actions) {}
}

import { createAction, props } from '@ngrx/store';
import { V2PartnerStatistic, V2WarningStatistic } from '../../../api/models';
import { StatisticsFilterModel } from '../../models/statistics-filter.model';

export const getPartnerStatisticsRequestAction = createAction(
  'GetPartnerStatisticsRequestAction',
);
export const getPartnerStatisticsSuccessAction = createAction(
  'GetPartnerStatisticsSuccessAction',
  props<{ partners: V2PartnerStatistic[] }>(),
);
export const getPartnerStatisticsFailureAction = createAction(
  'GetPartnerStatisticsFailureAction',
  props<{ error: Error }>(),
);

export const getFilteredPartnerStatisticsRequestAction = createAction(
  'GetFilteredPartnerStatisticsRequestAction',
  props<{
    filter: StatisticsFilterModel;
  }>(),
);
export const getFilteredPartnerStatisticsSuccessAction = createAction(
  'GetFilteredPartnerStatisticsSuccessAction',
  props<{ partners: V2PartnerStatistic[] }>(),
);
export const getFilteredPartnerStatisticsFailureAction = createAction(
  'GetFilteredPartnerStatisticsFailureAction',
  props<{ error: Error }>(),
);

export const getWarningStatisticsRequestAction = createAction(
  'GetWarningStatisticsRequestAction',
);
export const getWarningStatisticsSuccessAction = createAction(
  'GetWarningStatisticsSuccessAction',
  props<{ warnings: V2WarningStatistic[] }>(),
);
export const getWarningStatisticsFailureAction = createAction(
  'GetWarningStatisticsFailureAction',
  props<{ error: Error }>(),
);

export const getFilteredWarningStatisticsRequestAction = createAction(
  'GetFilteredWarningStatisticsRequestAction',
  props<{
    filter: StatisticsFilterModel;
  }>(),
);
export const getFilteredWarningStatisticsSuccessAction = createAction(
  'GetFilteredWarningStatisticsSuccessAction',
  props<{ warnings: V2WarningStatistic[] }>(),
);
export const getFilteredWarningStatisticsFailureAction = createAction(
  'GetFilteredWarningStatisticsFailureAction',
  props<{ error: Error }>(),
);

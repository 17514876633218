import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(public readonly fireAuth: AngularFireAuth) {}

  googleLogin(): Observable<void> {
    return from(
      this.fireAuth.signInWithRedirect(new firebase.auth.GoogleAuthProvider()),
    );
  }

  logout(): Observable<void> {
    return from(this.fireAuth.signOut());
  }

  get getRedirectResult(): Observable<firebase.auth.UserCredential> {
    return from(this.fireAuth.getRedirectResult());
  }

  get getIdToken(): Observable<string | null> {
    return this.fireAuth.idToken;
  }
}

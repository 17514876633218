import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { APIClient } from '../../../api';
import { of } from 'rxjs';
import { AppState } from '../reducers';
import { select, Store } from '@ngrx/store';
import { Translation, TRANSLATION } from '../../i18n/utils';
import {
  getUsersFailureAction,
  getUsersRequestAction,
  getUsersSuccessAction,
  patchUserFailureAction,
  patchUserRequestAction,
  patchUserSuccessAction,
} from '../actions/users.actions';
import { $usersLoadedCount } from '../selectors/users.selectors';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SNACKBAR_DELAY } from '../../app.constants';

@Injectable()
export class UsersEffects {
  getUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUsersRequestAction, patchUserSuccessAction),
      withLatestFrom(this.store.pipe(select($usersLoadedCount))),
      switchMap(() =>
        this.api.getUsers({}).pipe(
          map(({ users }) =>
            getUsersSuccessAction({
              users,
            }),
          ),
          catchError(error => of(getUsersFailureAction({ error }))),
        ),
      ),
    ),
  );

  patchUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patchUserRequestAction),
      switchMap(({ userId, role }) =>
        this.api.patchUser({ id: userId, role }).pipe(
          map(user => {
            this.snackBar.open(
              this.lang.common.saveSuccess,
              this.lang.common.close,
              {
                duration: SNACKBAR_DELAY,
              },
            );
            return patchUserSuccessAction({ user });
          }),
          catchError(error => of(patchUserFailureAction({ error }))),
        ),
      ),
    ),
  );

  constructor(
    @Inject(TRANSLATION) public readonly lang: Translation,
    private readonly actions$: Actions,
    private readonly api: APIClient,
    private readonly store: Store<AppState>,
    private readonly snackBar: MatSnackBar,
  ) {}
}

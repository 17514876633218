import { Action, createReducer, on } from '@ngrx/store';
import {
  loginFromLastSessionAction,
  loginSuccessAction,
  logoutSuccessAction,
} from '../actions/authentication.actions';
import firebase from 'firebase/compat';
import IdTokenResult = firebase.auth.IdTokenResult;

export interface AuthenticationState {
  loggedIn: boolean;
  userInfo: IdTokenResult | null;
}

const initialState = {
  loggedIn: false,
  userInfo: null,
};

const reducer = createReducer(
  initialState,
  on(loginSuccessAction, loginFromLastSessionAction, (_, { userInfo }) => ({
    loggedIn: true,
    userInfo: {
      ...userInfo,
      claims: {
        ...(userInfo.claims || {}),
        permissions:
          userInfo.claims && userInfo.claims.permissions
            ? userInfo.claims.permissions
            : [],
      },
    },
  })),
  on(logoutSuccessAction, () => initialState),
);

export function authenticationReducer(
  state: AuthenticationState | undefined,
  action: Action,
): AuthenticationState {
  return reducer(state, action);
}

import { Action, createReducer, on } from '@ngrx/store';
import {
  getIntegrationFailureAction,
  getIntegrationRequestAction,
  getIntegrationSuccessAction,
} from '../actions/integrations.actions';
import { StateStatus } from '../common.model';

export interface IntegrationsState {
  integration: {} | null;
}

const initialState: IntegrationsState = {
  integration: null,
};

const reducer = createReducer(
  initialState,
  on(getIntegrationRequestAction, state => ({
    ...state,
    status: StateStatus.LOADING,
  })),
  on(getIntegrationSuccessAction, (state, { integration }) => ({
    ...state,
    integration: integration,
  })),
  on(getIntegrationFailureAction, (state, { error }) => ({
    ...state,
    error,
    status: StateStatus.ERROR,
  })),
);

export function integrationsReducer(
  state: IntegrationsState,
  action: Action,
): IntegrationsState {
  return reducer(state, action);
}

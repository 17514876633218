import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { switchMap, take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(readonly auth: AuthService) {}

  intercept(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    request: HttpRequest<any>,
    next: HttpHandler,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<HttpEvent<any>> {
    if (/^(?:\/|.\/)?assets/.test(request.url)) {
      return next.handle(request);
    }

    return this.auth.getIdToken.pipe(
      take(1),
      switchMap(idToken =>
        next.handle(
          request.clone({
            setHeaders: { Authorization: `Bearer ${idToken}` },
          }),
        ),
      ),
    );
  }
}

import { Action, createReducer, on } from '@ngrx/store';
import { V2PartnerStatistic, V2WarningStatistic } from '../../../api/models';
import {
  getFilteredPartnerStatisticsSuccessAction,
  getFilteredWarningStatisticsFailureAction,
  getFilteredWarningStatisticsSuccessAction,
  getPartnerStatisticsSuccessAction,
  getWarningStatisticsFailureAction,
  getWarningStatisticsSuccessAction,
} from '../actions/statistics.actions';

export interface StatisticsState {
  warnings: V2WarningStatistic[];
  partners: V2PartnerStatistic[];
}

const initialState: StatisticsState = {
  warnings: [],
  partners: [],
};

const reducer = createReducer(
  initialState,
  on(
    getPartnerStatisticsSuccessAction,
    getFilteredPartnerStatisticsSuccessAction,
    (state, { partners }) => ({
      ...state,
      partners,
    }),
  ),
  on(
    getWarningStatisticsSuccessAction,
    getFilteredWarningStatisticsSuccessAction,
    (state, { warnings }) => ({
      ...state,
      warnings,
    }),
  ),
  on(
    getWarningStatisticsFailureAction,
    getFilteredWarningStatisticsFailureAction,
    (state, { error }) => ({
      ...state,
      error,
    }),
  ),
);

export function statisticsReducer(
  state: StatisticsState,
  action: Action,
): StatisticsState {
  return reducer(state, action);
}

import { clientsAdapter } from '../reducers/clients.reducer';
import { AppState } from '../reducers';
import { createSelector } from '@ngrx/store';
import { $partnerNames } from './partners.selectors';
import { $selectedClientId } from './route.selectors';
import {
  V2Contact,
  V2ContactType,
  V2Location,
  V2ActualLocation,
} from '../../../api/models';

const { selectAll: selectClients } = clientsAdapter.getSelectors();

export const $clientsState = ({ clients }: AppState) => clients;

export const $clients = createSelector($clientsState, selectClients);

export const $clientsTableData = createSelector(
  $clients,
  $partnerNames,
  (clients, names) =>
    clients.map(client => ({
      ...client,
      partnerId: names[client.partnerId] || client.partnerId,
    })),
);

export const $selectedClient = createSelector(
  $clientsState,
  $selectedClientId,
  (clients, id) => clients.entities[id],
);

export const $selectedClientContacts = createSelector(
  $selectedClient,
  (client): V2Contact[] => (client ? client.contacts : []),
);

export const $selectedClientPhoneNumbers = createSelector(
  $selectedClientContacts,
  (contacts): V2Contact[] =>
    contacts.filter(contact => contact.type === V2ContactType.SMS),
);
export const $selectedClientEmailAddresses = createSelector(
  $selectedClientContacts,
  (contacts): V2Contact[] =>
    contacts.filter(contact => contact.type === V2ContactType.EMAIL),
);

export const $selectedClientLocations = createSelector(
  $selectedClient,
  (client): V2Location[] => (client ? client.locations : []),
);

export const $selectedClientActualLocations = createSelector(
  $selectedClient,
  (client): V2ActualLocation[] =>
    client && client.actualLocation ? [client.actualLocation] : [],
);

export const $isClientListFinal = createSelector(
  $clientsState,
  ({ isListFinal }): boolean => isListFinal,
);

export const $clientsLoadedCount = createSelector(
  $clients,
  ({ length }): number => length,
);

/* eslint-disable */

import * as models from '../models';

/* pre-prepared guards for build in complex types */

function _isBlob(arg: any): arg is Blob {
  return arg != null && typeof arg.size === 'number' && typeof arg.type === 'string' && typeof arg.slice === 'function';
}

export function isFile(arg: any): arg is File {
return arg != null && typeof arg.lastModified === 'number' && typeof arg.name === 'string' && _isBlob(arg);
}

/* generated type guards */

export function isV2Client(arg: any): arg is models.V2Client {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // actualLocation?: V2ActualLocation[]
    ( typeof arg.actualLocation === 'undefined' || arg.actualLocation === null || isV2ActualLocation(arg.actualLocation) ) &&
    // disableLastNotifiedCheck?: boolean
    ( typeof arg.disableLastNotifiedCheck === 'undefined' || arg.disableLastNotifiedCheck === null || typeof arg.disableLastNotifiedCheck === 'boolean' ) &&
    // contacts?: V2Contact[]
    ( typeof arg.contacts === 'undefined' || arg.contacts === null || (Array.isArray(arg.contacts) && arg.contacts.every((item: unknown) => isV2Contact(item))) ) &&
    // createdAt?: string
    ( typeof arg.createdAt === 'undefined' || arg.createdAt === null || typeof arg.createdAt === 'string' ) &&
    // deletedAt?: string
    ( typeof arg.deletedAt === 'undefined' || arg.deletedAt === null || typeof arg.deletedAt === 'string' ) &&
    // givenName?: string
    ( typeof arg.givenName === 'undefined' || arg.givenName === null || typeof arg.givenName === 'string' ) &&
    // id?: string
    ( typeof arg.id === 'undefined' || arg.id === null || typeof arg.id === 'string' ) &&
    // lastNotified?: string
    ( typeof arg.lastNotified === 'undefined' || arg.lastNotified === null || typeof arg.lastNotified === 'string' ) &&
    // locations?: V2Location[]
    ( typeof arg.locations === 'undefined' || arg.locations === null || (Array.isArray(arg.locations) && arg.locations.every((item: unknown) => isV2Location(item))) ) &&
    // note?: string
    ( typeof arg.note === 'undefined' || arg.note === null || typeof arg.note === 'string' ) &&
    // partner?: V2Partner
    ( typeof arg.partner === 'undefined' || arg.partner === null || isV2Partner(arg.partner) ) &&
    // partnerId?: string
    ( typeof arg.partnerId === 'undefined' || arg.partnerId === null || typeof arg.partnerId === 'string' ) &&
    // surname?: string
    ( typeof arg.surname === 'undefined' || arg.surname === null || typeof arg.surname === 'string' ) &&
    // test?: boolean
    ( typeof arg.test === 'undefined' || arg.test === null || typeof arg.test === 'boolean' ) &&
    // uid?: number
    ( typeof arg.uid === 'undefined' || arg.uid === null || typeof arg.uid === 'string' ) &&

  true
  );
  }

export function isV2CloudTaskRequest(arg: any): arg is models.V2CloudTaskRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // type?: V2ContactType
    ( typeof arg.type === 'undefined' || isV2ContactType(arg.type) ) &&
    // url?: string
    ( typeof arg.url === 'undefined' || typeof arg.url === 'string' ) &&
    // version?: string
    ( typeof arg.version === 'undefined' || typeof arg.version === 'string' ) &&

  true
  );
  }

export function isV2Contact(arg: any): arg is models.V2Contact {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // client?: V2Client
    ( typeof arg.client === 'undefined' || arg.client === null || isV2Client(arg.client) ) &&
    // clientId?: string
    ( typeof arg.clientId === 'undefined' || arg.clientId === null || typeof arg.clientId === 'string' ) &&
    // createdAt?: string
    ( typeof arg.createdAt === 'undefined' || arg.createdAt === null || typeof arg.createdAt === 'string' ) &&
    // deletedAt?: string
    ( typeof arg.deletedAt === 'undefined' || arg.deletedAt === null || typeof arg.deletedAt === 'string' ) &&
    // id?: string
    ( typeof arg.id === 'undefined' || arg.id === null || typeof arg.id === 'string' ) &&
    // type?: string
    ( typeof arg.type === 'undefined' || arg.type === null || typeof arg.type === 'string' ) &&
    // value?: string
    ( typeof arg.value === 'undefined' || arg.value === null || typeof arg.value === 'string' ) &&

  true
  );
  }

export function isV2ActualLocation(arg: any): arg is models.V2ActualLocation {
  return (
      arg != null &&
      typeof arg === 'object' &&
      // client?: V2Client
      ( typeof arg.client === 'undefined' || arg.client === null || isV2Client(arg.client) ) &&
      // clientId?: string
      ( typeof arg.clientId === 'undefined' || arg.clientId === null || typeof arg.clientId === 'string' ) &&
      // createdAt?: string
      ( typeof arg.createdAt === 'undefined' || arg.createdAt === null || typeof arg.createdAt === 'string' ) &&
      // deletedAt?: string
      ( typeof arg.deletedAt === 'undefined' || arg.deletedAt === null || typeof arg.deletedAt === 'string' ) &&
      // id?: string
      ( typeof arg.id === 'undefined' || arg.id === null || typeof arg.id === 'string' ) &&
      // lat?: number
      ( typeof arg.lat === 'undefined' || arg.lat === null || typeof arg.lat === 'number' ) &&
      // lon?: number
      ( typeof arg.lon === 'undefined' || arg.lon === null || typeof arg.lon === 'number' ) &&
      // lon?: number
      ( typeof arg.radius === 'undefined' || arg.radius === null || typeof arg.radius === 'number' ) &&

      true
  );
}

export function isV2ContactRequest(arg: any): arg is models.V2ContactRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // type?: V2ContactType
    ( typeof arg.type === 'undefined' || isV2ContactType(arg.type) ) &&
    // value?: string
    ( typeof arg.value === 'undefined' || typeof arg.value === 'string' ) &&

  true
  );
  }

export function isV2ContactType(arg: any): arg is models.V2ContactType {
  return false
   || arg === models.V2ContactType.UNKNOWN_CONTACT
   || arg === models.V2ContactType.EMAIL
   || arg === models.V2ContactType.SMS
   || arg === models.V2ContactType.WEB_HOOK
   || arg === models.V2ContactType.WELCOME_SMS
  ;
  }

export function isV2Country(arg: any): arg is models.V2Country {
  return false
   || arg === models.V2Country.UNKNOWN_COUNTRY
   || arg === models.V2Country.CZ
   || arg === models.V2Country.SK
   || arg === models.V2Country.GLOBAL
  ;
  }

export function isV2CreateClientRequest(arg: any): arg is models.V2CreateClientRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // contacts?: V2ContactRequest[]
    ( typeof arg.contacts === 'undefined' || (Array.isArray(arg.contacts) && arg.contacts.every((item: unknown) => isV2ContactRequest(item))) ) &&
    // givenName?: string
    ( typeof arg.givenName === 'undefined' || typeof arg.givenName === 'string' ) &&
    // language?: V2Country
    ( typeof arg.language === 'undefined' || isV2Country(arg.language) ) &&
    // locations?: V2LocationRequest[]
    ( typeof arg.locations === 'undefined' || (Array.isArray(arg.locations) && arg.locations.every((item: unknown) => isV2LocationRequest(item))) ) &&
    // partnerId?: string
    ( typeof arg.partnerId === 'undefined' || typeof arg.partnerId === 'string' ) &&
    // surname?: string
    ( typeof arg.surname === 'undefined' || typeof arg.surname === 'string' ) &&
    // test?: boolean
    ( typeof arg.test === 'undefined' || typeof arg.test === 'boolean' ) &&
    // uid?: number
    ( typeof arg.uid === 'undefined' || typeof arg.uid === 'number' ) &&

  true
  );
  }

export function isV2CreateContactRequest(arg: any): arg is models.V2CreateContactRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // clientId?: string
    ( typeof arg.clientId === 'undefined' || typeof arg.clientId === 'string' ) &&
    // type?: V2ContactType
    ( typeof arg.type === 'undefined' || isV2ContactType(arg.type) ) &&
    // value?: string
    ( typeof arg.value === 'undefined' || typeof arg.value === 'string' ) &&

  true
  );
  }

export function isV2CreateLocationRequest(arg: any): arg is models.V2CreateLocationRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // clientId?: string
    ( typeof arg.clientId === 'undefined' || typeof arg.clientId === 'string' ) &&
    // elevation?: number
    ( typeof arg.elevation === 'undefined' || typeof arg.elevation === 'number' ) &&
    // language?: V2Country
    ( typeof arg.language === 'undefined' || isV2Country(arg.language) ) &&
    // lat?: number
    ( typeof arg.lat === 'undefined' || typeof arg.lat === 'number' ) &&
    // lng?: number
    ( typeof arg.lng === 'undefined' || typeof arg.lng === 'number' ) &&
    // zip?: string
    ( typeof arg.zip === 'undefined' || typeof arg.zip === 'string' ) &&

  true
  );
  }

export function isV2CreatePartnerRequest(arg: any): arg is models.V2CreatePartnerRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // name?: string
    ( typeof arg.name === 'undefined' || typeof arg.name === 'string' ) &&
    // note?: string
    ( typeof arg.note === 'undefined' || typeof arg.note === 'string' ) &&
    // smsNo?: string
    ( typeof arg.smsNo === 'undefined' || typeof arg.smsNo === 'string' ) &&

  true
  );
  }

export function isV2CreateWarningRequest(arg: any): arg is models.V2CreateWarningRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // country?: V2Country
    ( typeof arg.country === 'undefined' || isV2Country(arg.country) ) &&
    // dryRun?: boolean
    ( typeof arg.dryRun === 'undefined' || typeof arg.dryRun === 'boolean' ) &&
    // from?: string
    ( typeof arg.from === 'undefined' || typeof arg.from === 'string' ) &&
    // fromElevation?: number
    ( typeof arg.fromElevation === 'undefined' || typeof arg.fromElevation === 'number' ) &&
    // map?: string
    ( typeof arg.map === 'undefined' || typeof arg.map === 'string' ) &&
    // message?: V2WarningMessage
    ( typeof arg.message === 'undefined' || isV2WarningMessage(arg.message) ) &&
    // notNotifyReported?: boolean
    ( typeof arg.notNotifyReported === 'undefined' || typeof arg.notNotifyReported === 'boolean' ) &&
    // test?: boolean
    ( typeof arg.test === 'undefined' || typeof arg.test === 'boolean' ) &&
    // to?: string
    ( typeof arg.to === 'undefined' || typeof arg.to === 'string' ) &&
    // type?: string
    ( typeof arg.type === 'undefined' || typeof arg.type === 'string' ) &&

  true
  );
  }

export function isV2CreateWarningResponse(arg: any): arg is models.V2CreateWarningResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // clients?: number
    ( typeof arg.clients === 'undefined' || typeof arg.clients === 'number' ) &&
    // id?: string
    ( typeof arg.id === 'undefined' || typeof arg.id === 'string' ) &&
    // notifications?: number
    ( typeof arg.notifications === 'undefined' || typeof arg.notifications === 'number' ) &&
    // zipCodes?: number
    ( typeof arg.zipCodes === 'undefined' || typeof arg.zipCodes === 'number' ) &&

  true
  );
  }

export function isV2Empty(arg: any): arg is models.V2Empty {
  return (
  arg != null &&
  typeof arg === 'object' &&

  true
  );
  }

export function isV2GetClientsResponse(arg: any): arg is models.V2GetClientsResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // clients?: V2Client[]
    ( typeof arg.clients === 'undefined' || (Array.isArray(arg.clients) && arg.clients.every((item: unknown) => isV2Client(item))) ) &&

  true
  );
  }

export function isV2GetPartnersInvoiceResponse(arg: any): arg is models.V2GetPartnersInvoiceResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // months?: V2PartnerInvoice[]
    ( typeof arg.months === 'undefined' || (Array.isArray(arg.months) && arg.months.every((item: unknown) => isV2PartnerInvoice(item))) ) &&

  true
  );
  }

export function isV2GetPartnerStatisticsResponse(arg: any): arg is models.V2GetPartnerStatisticsResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // partners?: V2PartnerStatistic[]
    ( typeof arg.partners === 'undefined' || (Array.isArray(arg.partners) && arg.partners.every((item: unknown) => isV2PartnerStatistic(item))) ) &&

  true
  );
  }

export function isV2GetWarningsInvoiceResponse(arg: any): arg is models.V2GetWarningsInvoiceResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // warnings?: V2WarningInvoice[]
    ( typeof arg.warnings === 'undefined' || (Array.isArray(arg.warnings) && arg.warnings.every((item: unknown) => isV2WarningInvoice(item))) ) &&

  true
  );
  }

export function isV2GetWarningStatisticsResponse(arg: any): arg is models.V2GetWarningStatisticsResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // warnings?: V2WarningStatistic[]
    ( typeof arg.warnings === 'undefined' || (Array.isArray(arg.warnings) && arg.warnings.every((item: unknown) => isV2WarningStatistic(item))) ) &&

  true
  );
  }

export function isV2IdRequest(arg: any): arg is models.V2IdRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // id?: string
    ( typeof arg.id === 'undefined' || typeof arg.id === 'string' ) &&

  true
  );
  }

export function isV2Integration(arg: any): arg is models.V2Integration {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // active?: boolean
    ( typeof arg.active === 'undefined' || typeof arg.active === 'boolean' ) &&
    // country?: V2Country
    ( typeof arg.country === 'undefined' || isV2Country(arg.country) ) &&
    // createdAt?: string
    ( typeof arg.createdAt === 'undefined' || typeof arg.createdAt === 'string' ) &&
    // id?: string
    ( typeof arg.id === 'undefined' || typeof arg.id === 'string' ) &&
    // partnerId?: string
    ( typeof arg.partnerId === 'undefined' || typeof arg.partnerId === 'string' ) &&
    // type?: string
    ( typeof arg.type === 'undefined' || typeof arg.type === 'string' ) &&
    // value?: string
    ( typeof arg.value === 'undefined' || typeof arg.value === 'string' ) &&

  true
  );
  }

export function isV2Location(arg: any): arg is models.V2Location {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // client?: V2Client
    ( typeof arg.client === 'undefined' || arg.client === null || isV2Client(arg.client) ) &&
    // clientId?: string
    ( typeof arg.clientId === 'undefined' || arg.clientId === null || typeof arg.clientId === 'string' ) &&
    // country?: V2Country
    ( typeof arg.country === 'undefined' || arg.country === null || isV2Country(arg.country) ) &&
    // createdAt?: string
    ( typeof arg.createdAt === 'undefined' || arg.createdAt === null || typeof arg.createdAt === 'string' ) &&
    // deletedAt?: string
    ( typeof arg.deletedAt === 'undefined' || arg.deletedAt === null || typeof arg.deletedAt === 'string' ) &&
    // elevation?: number
    ( typeof arg.elevation === 'undefined' || arg.elevation === null || typeof arg.elevation === 'number' ) &&
    // id?: string
    ( typeof arg.id === 'undefined' || arg.id === null || typeof arg.id === 'string' ) &&
    // language?: V2Country
    ( typeof arg.language === 'undefined' || arg.language === null || isV2Country(arg.language) ) &&
    // lat?: number
    ( typeof arg.lat === 'undefined' || arg.lat === null || typeof arg.lat === 'number' ) &&
    // lng?: number
    ( typeof arg.lng === 'undefined' || arg.lng === null || typeof arg.lng === 'number' ) &&
    // zip?: string
    ( typeof arg.zip === 'undefined' || arg.zip === null || typeof arg.zip === 'string' ) &&

  true
  );
  }

export function isV2LocationRequest(arg: any): arg is models.V2LocationRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // elevation?: number
    ( typeof arg.elevation === 'undefined' || typeof arg.elevation === 'number' ) &&
    // language?: V2Country
    ( typeof arg.language === 'undefined' || isV2Country(arg.language) ) &&
    // lat?: number
    ( typeof arg.lat === 'undefined' || typeof arg.lat === 'number' ) &&
    // lng?: number
    ( typeof arg.lng === 'undefined' || typeof arg.lng === 'number' ) &&
    // zip?: string
    ( typeof arg.zip === 'undefined' || typeof arg.zip === 'string' ) &&

  true
  );
  }

export function isV2Partner(arg: any): arg is models.V2Partner {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // clients?: V2Client[]
    ( typeof arg.clients === 'undefined' || typeof arg.clients === null || (Array.isArray(arg.clients) && arg.clients.every((item: unknown) => isV2Client(item))) ) &&
    // createdAt?: string
    ( typeof arg.createdAt === 'undefined' || arg.createdAt === null || typeof arg.createdAt === 'string' ) &&
    // deletedAt?: string
    ( typeof arg.deletedAt === 'undefined' || arg.deletedAt === null || typeof arg.deletedAt === 'string' ) &&
    // emailFrom?: string
    ( typeof arg.emailFrom === 'undefined' || arg.emailFrom === null || typeof arg.emailFrom === 'string' ) &&
    // id?: string
    ( typeof arg.id === 'undefined' || arg.id === null || typeof arg.id === 'string' ) &&
    // integrations?: V2Integration[]
    ( typeof arg.integrations === 'undefined' || arg.integrations === null || (Array.isArray(arg.integrations) && arg.integrations.every((item: unknown) => isV2Integration(item))) ) &&
    // name?: string
    ( typeof arg.name === 'undefined' || arg.name === null || typeof arg.name === 'string' ) &&
    // note?: string
    ( typeof arg.note === 'undefined' || arg.note === null || typeof arg.note === 'string' ) &&
    // sendWarnings?: boolean
    ( typeof arg.sendWarnings === 'undefined' || arg.sendWarnings === null || typeof arg.sendWarnings === 'boolean' ) &&
    // smsNo?: string
    ( typeof arg.smsNo === 'undefined' || arg.smsNo === null || typeof arg.smsNo === 'string' ) &&
    // templates?: V2Template[]
    ( typeof arg.templates === 'undefined' || arg.templates === null || (Array.isArray(arg.templates) && arg.templates.every((item: unknown) => isV2Template(item))) ) &&

  true
  );
  }

export function isV2PartnerInvoice(arg: any): arg is models.V2PartnerInvoice {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // clientsAdded?: number
    ( typeof arg.clientsAdded === 'undefined' || typeof arg.clientsAdded === 'number' ) &&
    // clientsCountEnd?: number
    ( typeof arg.clientsCountEnd === 'undefined' || typeof arg.clientsCountEnd === 'number' ) &&
    // clientsCountStart?: number
    ( typeof arg.clientsCountStart === 'undefined' || typeof arg.clientsCountStart === 'number' ) &&
    // clientsDeleted?: number
    ( typeof arg.clientsDeleted === 'undefined' || typeof arg.clientsDeleted === 'number' ) &&
    // monthStart?: string
    ( typeof arg.monthStart === 'undefined' || typeof arg.monthStart === 'string' ) &&
    // warningsCount?: number
    ( typeof arg.warningsCount === 'undefined' || typeof arg.warningsCount === 'number' ) &&

  true
  );
  }

export function isV2Partners(arg: any): arg is models.V2Partners {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // partners?: V2Partner[]
    ( typeof arg.partners === 'undefined' || (Array.isArray(arg.partners) && arg.partners.every((item: unknown) => isV2Partner(item))) ) &&

  true
  );
  }

export function isV2PartnerStatistic(arg: any): arg is models.V2PartnerStatistic {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // clientsAdded?: number
    ( typeof arg.clientsAdded === 'undefined' || typeof arg.clientsAdded === 'number' ) &&
    // clientsCount?: number
    ( typeof arg.clientsCount === 'undefined' || typeof arg.clientsCount === 'number' ) &&
    // clientsDeleted?: number
    ( typeof arg.clientsDeleted === 'undefined' || typeof arg.clientsDeleted === 'number' ) &&
    // day?: string
    ( typeof arg.day === 'undefined' || typeof arg.day === 'string' ) &&
    // id?: string
    ( typeof arg.id === 'undefined' || typeof arg.id === 'string' ) &&
    // partnerId?: string
    ( typeof arg.partnerId === 'undefined' || typeof arg.partnerId === 'string' ) &&

  true
  );
  }

export function isV2PartnerStats(arg: any): arg is models.V2PartnerStats {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // clients?: number
    ( typeof arg.clients === 'undefined' || typeof arg.clients === 'number' ) &&
    // emails?: number
    ( typeof arg.emails === 'undefined' || typeof arg.emails === 'number' ) &&
    // notifications?: number
    ( typeof arg.notifications === 'undefined' || typeof arg.notifications === 'number' ) &&
    // sms?: number
    ( typeof arg.sms === 'undefined' || typeof arg.sms === 'number' ) &&

  true
  );
  }

export function isV2PatchClientRequest(arg: any): arg is models.V2PatchClientRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // givenName?: string
    ( typeof arg.givenName === 'undefined' || typeof arg.givenName === 'string' ) &&
    // id?: string
    ( typeof arg.id === 'undefined' || typeof arg.id === 'string' ) &&
    // language?: number
    ( typeof arg.language === 'undefined' || typeof arg.language === 'number' ) &&
    // note?: string
    ( typeof arg.note === 'undefined' || typeof arg.note === 'string' ) &&
    // partnerId?: string
    ( typeof arg.partnerId === 'undefined' || typeof arg.partnerId === 'string' ) &&
    // surname?: string
    ( typeof arg.surname === 'undefined' || typeof arg.surname === 'string' ) &&
    // test?: boolean
    ( typeof arg.test === 'undefined' || typeof arg.test === 'boolean' ) &&

  true
  );
  }

export function isV2PatchPartnerRequest(arg: any): arg is models.V2PatchPartnerRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // emailFrom?: string
    ( typeof arg.emailFrom === 'undefined' || typeof arg.emailFrom === 'string' ) &&
    // id?: string
    ( typeof arg.id === 'undefined' || typeof arg.id === 'string' ) &&
    // name?: string
    ( typeof arg.name === 'undefined' || typeof arg.name === 'string' ) &&
    // note?: string
    ( typeof arg.note === 'undefined' || typeof arg.note === 'string' ) &&
    // sendWarnings?: boolean
    ( typeof arg.sendWarnings === 'undefined' || typeof arg.sendWarnings === 'boolean' ) &&
    // smsNo?: string
    ( typeof arg.smsNo === 'undefined' || typeof arg.smsNo === 'string' ) &&

  true
  );
  }

export function isV2RoleRequest(arg: any): arg is models.V2RoleRequest {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // role?: string
    ( typeof arg.role === 'undefined' || typeof arg.role === 'string' ) &&
    // subject?: string
    ( typeof arg.subject === 'undefined' || typeof arg.subject === 'string' ) &&

  true
  );
  }

export function isV2Template(arg: any): arg is models.V2Template {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // createdAt?: string
    ( typeof arg.createdAt === 'undefined' || typeof arg.createdAt === 'string' ) &&
    // deletedAt?: string
    ( typeof arg.deletedAt === 'undefined' || typeof arg.deletedAt === 'string' ) &&
    // emailFrom?: string
    ( typeof arg.emailFrom === 'undefined' || typeof arg.emailFrom === 'string' ) &&
    // id?: string
    ( typeof arg.id === 'undefined' || typeof arg.id === 'string' ) &&
    // language?: V2Country
    ( typeof arg.language === 'undefined' || isV2Country(arg.language) ) &&
    // partner?: V2Partner
    ( typeof arg.partner === 'undefined' || isV2Partner(arg.partner) ) &&
    // partnerId?: string
    ( typeof arg.partnerId === 'undefined' || typeof arg.partnerId === 'string' ) &&
    // subject?: string
    ( typeof arg.subject === 'undefined' || typeof arg.subject === 'string' ) &&
    // text?: string
    ( typeof arg.text === 'undefined' || typeof arg.text === 'string' ) &&
    // type?: string
    ( typeof arg.type === 'undefined' || typeof arg.type === 'string' ) &&
    // values?: string[]
    ( typeof arg.values === 'undefined' || (Array.isArray(arg.values) && arg.values.every((item: unknown) => typeof item === 'string')) ) &&
    // warningType?: string
    ( typeof arg.warningType === 'undefined' || typeof arg.warningType === 'string' ) &&

  true
  );
  }

export function isV2VersionResponse(arg: any): arg is models.V2VersionResponse {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // version?: string
    ( typeof arg.version === 'undefined' || typeof arg.version === 'string' ) &&

  true
  );
  }

export function isV2Warning(arg: any): arg is models.V2Warning {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // canceledAt?: string
    ( typeof arg.canceledAt === 'undefined' || typeof arg.canceledAt === 'string' ) &&
    // clients?: number
    ( typeof arg.clients === 'undefined' || typeof arg.clients === 'number' ) &&
    // country?: string
    ( typeof arg.country === 'undefined' || typeof arg.country === 'string' ) &&
    // createdAt?: string
    ( typeof arg.createdAt === 'undefined' || typeof arg.createdAt === 'string' ) &&
    // doneAt?: string
    ( typeof arg.doneAt === 'undefined' || typeof arg.doneAt === 'string' ) &&
    // dummyStatus?: WarningStatus
    ( typeof arg.dummyStatus === 'undefined' || isWarningStatus(arg.dummyStatus) ) &&
    // emailsCount?: number
    ( typeof arg.emailsCount === 'undefined' || typeof arg.emailsCount === 'number' ) &&
    // emailsFailed?: number
    ( typeof arg.emailsFailed === 'undefined' || typeof arg.emailsFailed === 'number' ) &&
    // emailsSent?: number
    ( typeof arg.emailsSent === 'undefined' || typeof arg.emailsSent === 'number' ) &&
    // extractedAt?: string
    ( typeof arg.extractedAt === 'undefined' || typeof arg.extractedAt === 'string' ) &&
    // failedTasks?: number
    ( typeof arg.failedTasks === 'undefined' || typeof arg.failedTasks === 'number' ) &&
    // firstScheduledTask?: string
    ( typeof arg.firstScheduledTask === 'undefined' || typeof arg.firstScheduledTask === 'string' ) &&
    // from?: string
    ( typeof arg.from === 'undefined' || typeof arg.from === 'string' ) &&
    // fromElevation?: number
    ( typeof arg.fromElevation === 'undefined' || typeof arg.fromElevation === 'number' ) &&
    // generatedAt?: string
    ( typeof arg.generatedAt === 'undefined' || typeof arg.generatedAt === 'string' ) &&
    // id?: string
    ( typeof arg.id === 'undefined' || typeof arg.id === 'string' ) &&
    // map?: string
    ( typeof arg.map === 'undefined' || typeof arg.map === 'string' ) &&
    // notNotifyReported?: boolean
    ( typeof arg.notNotifyReported === 'undefined' || typeof arg.notNotifyReported === 'boolean' ) &&
    // partnerStatistics?: { [key: string]: V2PartnerStats }
    ( typeof arg.partnerStatistics === 'undefined' || isV2PartnerStats(arg.partnerStatistics) ) &&
    // processingAt?: string
    ( typeof arg.processingAt === 'undefined' || typeof arg.processingAt === 'string' ) &&
    // sendingAt?: string
    ( typeof arg.sendingAt === 'undefined' || typeof arg.sendingAt === 'string' ) &&
    // smsCount?: number
    ( typeof arg.smsCount === 'undefined' || typeof arg.smsCount === 'number' ) &&
    // smsFailed?: number
    ( typeof arg.smsFailed === 'undefined' || typeof arg.smsFailed === 'number' ) &&
    // smsSent?: number
    ( typeof arg.smsSent === 'undefined' || typeof arg.smsSent === 'number' ) &&
    // status?: string
    ( typeof arg.status === 'undefined' || typeof arg.status === 'string' ) &&
    // succeededTasks?: number
    ( typeof arg.succeededTasks === 'undefined' || typeof arg.succeededTasks === 'number' ) &&
    // tasksCount?: number
    ( typeof arg.tasksCount === 'undefined' || typeof arg.tasksCount === 'number' ) &&
    // templateValues?: { [key: string]: string }
    ( typeof arg.templateValues === 'undefined' || typeof arg.templateValues === 'string' ) &&
    // test?: boolean
    ( typeof arg.test === 'undefined' || typeof arg.test === 'boolean' ) &&
    // to?: string
    ( typeof arg.to === 'undefined' || typeof arg.to === 'string' ) &&
    // type?: string
    ( typeof arg.type === 'undefined' || typeof arg.type === 'string' ) &&
    // url?: string
    ( typeof arg.url === 'undefined' || typeof arg.url === 'string' ) &&
    // userEmail?: string
    ( typeof arg.userEmail === 'undefined' || typeof arg.userEmail === 'string' ) &&
    // userId?: string
    ( typeof arg.userId === 'undefined' || typeof arg.userId === 'string' ) &&
    // userName?: string
    ( typeof arg.userName === 'undefined' || typeof arg.userName === 'string' ) &&
    // zipCodes?: number
    ( typeof arg.zipCodes === 'undefined' || typeof arg.zipCodes === 'number' ) &&

  true
  );
  }

export function isV2WarningInvoice(arg: any): arg is models.V2WarningInvoice {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // clients?: number
    ( typeof arg.clients === 'undefined' || typeof arg.clients === 'number' ) &&
    // country?: string
    ( typeof arg.country === 'undefined' || typeof arg.country === 'string' ) &&
    // createdAt?: string
    ( typeof arg.createdAt === 'undefined' || typeof arg.createdAt === 'string' ) &&
    // id?: string
    ( typeof arg.id === 'undefined' || typeof arg.id === 'string' ) &&
    // sentEmail?: number
    ( typeof arg.sentEmail === 'undefined' || typeof arg.sentEmail === 'number' ) &&
    // sentSms?: number
    ( typeof arg.sentSms === 'undefined' || typeof arg.sentSms === 'number' ) &&
    // warningType?: string
    ( typeof arg.warningType === 'undefined' || typeof arg.warningType === 'string' ) &&

  true
  );
  }

export function isV2WarningMessage(arg: any): arg is models.V2WarningMessage {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // customEnding?: string
    ( typeof arg.customEnding === 'undefined' || typeof arg.customEnding === 'string' ) &&
    // templateValues?: { [key: string]: string }
    ( typeof arg.templateValues === 'undefined' || typeof arg.templateValues === 'string' ) &&

  true
  );
  }

export function isV2WarningStatistic(arg: any): arg is models.V2WarningStatistic {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // clients?: number
    ( typeof arg.clients === 'undefined' || typeof arg.clients === 'number' ) &&
    // created?: number
    ( typeof arg.created === 'undefined' || typeof arg.created === 'number' ) &&
    // day?: string
    ( typeof arg.day === 'undefined' || typeof arg.day === 'string' ) &&
    // id?: string
    ( typeof arg.id === 'undefined' || typeof arg.id === 'string' ) &&
    // notifications?: number
    ( typeof arg.notifications === 'undefined' || typeof arg.notifications === 'number' ) &&
    // partnerId?: string
    ( typeof arg.partnerId === 'undefined' || typeof arg.partnerId === 'string' ) &&

  true
  );
  }

export function isV2WarningType(arg: any): arg is models.V2WarningType {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // text?: string
    ( typeof arg.text === 'undefined' || typeof arg.text === 'string' ) &&
    // type?: string
    ( typeof arg.type === 'undefined' || typeof arg.type === 'string' ) &&
    // values?: string[]
    ( typeof arg.values === 'undefined' || (Array.isArray(arg.values) && arg.values.every((item: unknown) => typeof item === 'string')) ) &&

  true
  );
  }

export function isV2WarningTypes(arg: any): arg is models.V2WarningTypes {
  return (
  arg != null &&
  typeof arg === 'object' &&
    // types?: V2WarningType[]
    ( typeof arg.types === 'undefined' || (Array.isArray(arg.types) && arg.types.every((item: unknown) => isV2WarningType(item))) ) &&

  true
  );
  }

export function isWarningStatus(arg: any): arg is models.WarningStatus {
  return false
   || arg === models.WarningStatus.CREATED
   || arg === models.WarningStatus.CANCELED
   || arg === models.WarningStatus.PROCESSING
   || arg === models.WarningStatus.EXTRACTED_CLIENTS
   || arg === models.WarningStatus.GENERATED_MESSAGES
   || arg === models.WarningStatus.SENDING
   || arg === models.WarningStatus.DONE
  ;
  }



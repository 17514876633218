import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { APIClient } from '../../../api';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import {
  createDryRunWarningFailureAction,
  createDryRunWarningRequestAction,
  createDryRunWarningSuccessAction,
  createWarningFailureAction,
  createWarningRequestAction,
  createWarningSuccessAction,
  resetDryWarningStateAction,
} from '../actions/warning.actions';
import { of, Subject } from 'rxjs';
import { WarningSentComponent } from '../../views/platform/create-warning/dialog/warning-sent/warning-sent.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class ClearFormService {
  private readonly clearFormSource = new Subject<boolean>();
  clearForm$ = this.clearFormSource.asObservable();
  clearForm(): void {
    this.clearFormSource.next(true);
  }
}

@Injectable()
export class WarningEffects {
  createDryRunWarning$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createDryRunWarningRequestAction),
      switchMap(({ warningRequest }) =>
        this.api
          .createWarning({ body: { ...warningRequest, dryRun: true } })
          .pipe(
            map(warning => createDryRunWarningSuccessAction({ warning })),
            catchError(error =>
              of(createDryRunWarningFailureAction({ error })),
            ),
          ),
      ),
    ),
  );

  createWarning$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createWarningRequestAction),
      switchMap(({ warningRequest }) =>
        this.api
          .createWarning({ body: { ...warningRequest, dryRun: false } })
          .pipe(
            map(warning =>
              createWarningSuccessAction({ warning, warningRequest }),
            ),
            catchError(error => of(createWarningFailureAction({ error }))),
          ),
      ),
    ),
  );

  createWarningSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createWarningSuccessAction),
        tap(result => {
          // TODO: fix this, effect is called twice, why?
          this.clearFormService.clearForm();
          this.dialog.closeAll();
          const dialogRef = this.dialog.open(WarningSentComponent, {
            data: {
              result: result.warning,
            },
          });
          return dialogRef.afterClosed();
        }),
      ),
    { dispatch: false },
  );

  resetDryRunAfterCreate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createWarningSuccessAction),
      map(() => resetDryWarningStateAction()),
    ),
  );

  constructor(
    private readonly clearFormService: ClearFormService,
    private readonly actions$: Actions,
    private readonly api: APIClient,
    private readonly dialog: MatDialog,
  ) {}
}

import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { APIClient } from '../../../api';
import { of } from 'rxjs';
import { Translation, TRANSLATION } from '../../i18n/utils';
import { V2Integration } from '../../../api/models';
import {
  getIntegrationFailureAction,
  getIntegrationRequestAction,
  getIntegrationSuccessAction,
} from '../actions/integrations.actions';
import { getTemplatesSuccessAction } from '../actions/templates.actions';

@Injectable()
export class IntegrationsEffects {
  getIntegration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getIntegrationRequestAction),
      switchMap(({ integrationId, templates }) =>
        this.api.getIntegration({ integrationId, templates }).pipe(
          switchMap(integration =>
            of(
              getIntegrationSuccessAction({
                integration: integration as V2Integration,
              }),
              getTemplatesSuccessAction({
                templates: (integration as V2Integration).templates,
              }),
            ),
          ),
          catchError(error => of(getIntegrationFailureAction({ error }))),
        ),
      ),
    ),
  );

  constructor(
    @Inject(TRANSLATION) public readonly lang: Translation,
    private readonly actions$: Actions,
    private readonly api: APIClient,
  ) {}
}

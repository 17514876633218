import { createAction, props } from '@ngrx/store';
import { RouteModel } from '../../models/route.model';

export const navigationStartNotifyAction = createAction(
  'NavigationStartNotifyAction',
);

export const navigationEndNotifyAction = createAction(
  'NavigationEndNotifyAction',
  props<{ route: RouteModel }>(),
);

export const redirectToClientDetailAction = createAction(
  'RedirectToClientDetail',
  props<{ userId: string }>(),
);

export const redirectToPartnerDetailAction = createAction(
  'RedirectToPartnerDetail',
  props<{ partnerId: string }>(),
);

export const redirectToWarningDetailAction = createAction(
  'RedirectToWarningDetail',
  props<{ warningId: string }>(),
);

export const redirectToTemplateDetailAction = createAction(
  'RedirectToTemplateDetail',
  props<{ templateId: string }>(),
);

export const redirectToCreateTemplateAction = createAction(
  'RedirectToCreateTemplateDetail',
  props<{ integrationId: string }>(),
);

export const redirectToIntegrationDetailAction = createAction(
  'RedirectToIntegrationDetail',
  props<{ integrationId: string }>(),
);

export const redirectToClientsListAction = createAction(
  'RedirectToClientsList',
);

export const redirectToPartnerListAction = createAction(
  'RedirectToPartnerList',
);

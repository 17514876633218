/* eslint-disable */

import {HttpClient, HttpHeaders, HttpParameterCodec, HttpParams} from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { DefaultHttpOptions, HttpOptions, APIClientInterface } from './';

import * as models from './models';

export const USE_DOMAIN = new InjectionToken<string>('APIClient_USE_DOMAIN');
export const USE_HTTP_OPTIONS = new InjectionToken<HttpOptions>('APIClient_USE_HTTP_OPTIONS');

type APIHttpOptions = HttpOptions & {
  headers: HttpHeaders;
  params: HttpParams;
  responseType?: 'arraybuffer' | 'blob' | 'text' | 'json';
};

// Encoder that properly encodes character like "+" in URL params.
// https://stackoverflow.com/a/54921164/13343708
class HttpUrlEncodingCodec implements HttpParameterCodec {
  encodeKey(k: string): string { return standardEncoding(k); }
  encodeValue(v: string): string { return standardEncoding(v); }
  decodeKey(k: string): string { return decodeURIComponent(k); }
  decodeValue(v: string) { return decodeURIComponent(v); }
}
function standardEncoding(v: string): string {
  return encodeURIComponent(v);
}

/**
 * Created with https://github.com/flowup/api-client-generator
 */
@Injectable()
export class APIClient implements APIClientInterface {

  readonly options: APIHttpOptions;

  readonly domain: string = `//${window.location.hostname}${window.location.port ? ':'+window.location.port : ''}`;

  constructor(private readonly http: HttpClient,
              @Optional() @Inject(USE_DOMAIN) domain?: string,
              @Optional() @Inject(USE_HTTP_OPTIONS) options?: DefaultHttpOptions) {

    if (domain != null) {
      this.domain = domain;
    }

    this.options = {
      headers: new HttpHeaders(options && options.headers ? options.headers : {}),
      params: new HttpParams(options && options.params
          ? options.params : { encoder: new HttpUrlEncodingCodec() }),
      ...(options && options.reportProgress ? { reportProgress: options.reportProgress } : {}),
      ...(options && options.withCredentials ? { withCredentials: options.withCredentials } : {})
    };
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  warningDummyEndpoint(
    args: {
      body: models.V2Warning,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2Warning> {
    const path = `/dummy/warning`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.V2Warning>('POST', path, options, JSON.stringify(args.body));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  setRole(
    args: {
      body: models.V2RoleRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2Empty> {
    const path = `/v2/admin/role`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.V2Empty>('POST', path, options, JSON.stringify(args.body));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  getClients(
    args: {
      id?: string,
      uid?: number,
      givenName?: string,
      surname?: string,
      email?: string,
      phoneNumber?: string,
      zip?: string,
      country?: 'UNKNOWN_COUNTRY' | 'CZ' | 'SK' | 'GLOBAL',
      test?: boolean,
      partnerId?: string,
      limit?: number,
      offset?: number,
      orderBy?: string,
      order?: string,
      includeDeleted?: number,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2GetClientsResponse> {
    const path = `/v2/client`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('id' in args) {
      options.params = options.params.set('id', String(args.id));
    }
    if ('uid' in args) {
      options.params = options.params.set('uid', String(args.uid));
    }
    if ('givenName' in args) {
      options.params = options.params.set('givenName', String(args.givenName));
    }
    if ('surname' in args) {
      options.params = options.params.set('surname', String(args.surname));
    }
    if ('email' in args) {
      options.params = options.params.set('email', String(args.email));
    }
    if ('phoneNumber' in args) {
      options.params = options.params.set('phoneNumber', String(args.phoneNumber));
    }
    if ('zip' in args) {
      options.params = options.params.set('zip', String(args.zip));
    }
    if ('country' in args) {
      options.params = options.params.set('country', String(args.country));
    }
    if ('test' in args) {
      options.params = options.params.set('test', String(args.test));
    }
    if ('partnerId' in args) {
      options.params = options.params.set('partnerId', String(args.partnerId));
    }
    if ('limit' in args) {
      options.params = options.params.set('limit', String(args.limit));
    }
    if ('offset' in args) {
      options.params = options.params.set('offset', String(args.offset));
    }
    if ('orderBy' in args) {
      options.params = options.params.set('orderBy', String(args.orderBy));
    }
    if ('order' in args) {
      options.params = options.params.set('order', String(args.order));
    }
    if ('includeDeleted' in args) {
      options.params = options.params.set('includeDeleted', String(args.includeDeleted));
    }
    return this.sendRequest<models.V2GetClientsResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  createClient(
    args: {
      body: models.V2CreateClientRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2Client> {
    const path = `/v2/client`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.V2Client>('POST', path, options, JSON.stringify(args.body));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  createClientContact(
    args: {
      clientId: string,
      body: models.V2CreateContactRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2Contact> {
    const path = `/v2/client/${args.clientId}/contact`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.V2Contact>('POST', path, options, JSON.stringify(args.body));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  deleteClientContact(
    args: {
      clientId: string,
      resourceId: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2Empty> {
    const path = `/v2/client/${args.clientId}/contact/${args.resourceId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.V2Empty>('DELETE', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  createClientLocation(
    args: {
      clientId: string,
      body: models.V2CreateLocationRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2Location> {
    const path = `/v2/client/${args.clientId}/location`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.V2Location>('POST', path, options, JSON.stringify(args.body));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  deleteClientLocation(
    args: {
      clientId: string,
      resourceId: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2Empty> {
    const path = `/v2/client/${args.clientId}/location/${args.resourceId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.V2Empty>('DELETE', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  getClient(
    args: {
      id: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2Client> {
    const path = `/v2/client/${args.id}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    options.params = options.params.set('includeDeleted', String(1));

    return this.sendRequest<models.V2Client>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  deleteClient(
    args: {
      id: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2Empty> {
    const path = `/v2/client/${args.id}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.V2Empty>('DELETE', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  patchClient(
    args: {
      id: string,
      body: models.V2PatchClientRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2Client> {
    const path = `/v2/client/${args.id}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.V2Client>('PATCH', path, options, JSON.stringify(args.body));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  getPartnersInvoice(
    args: {
      partnerId: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2GetPartnersInvoiceResponse> {
    const path = `/v2/invoice/partner/${args.partnerId}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.V2GetPartnersInvoiceResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  getWarningsInvoice(
    args: {
      from?: string,
      to?: string,
      partnerId?: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2GetWarningsInvoiceResponse> {
    const path = `/v2/invoice/warning`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('from' in args) {
      options.params = options.params.set('from', String(args.from));
    }
    if ('to' in args) {
      options.params = options.params.set('to', String(args.to));
    }
    if ('partnerId' in args) {
      options.params = options.params.set('partnerId', String(args.partnerId));
    }
    return this.sendRequest<models.V2GetWarningsInvoiceResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  getPartners(
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2Partners> {
    const path = `/v2/partner`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.V2Partners>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  createPartner(
    args: {
      body: models.V2CreatePartnerRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2Partner> {
    const path = `/v2/partner`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.V2Partner>('POST', path, options, JSON.stringify(args.body));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  getPartner(
    args: {
      id: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2Partner> {
    const path = `/v2/partner/${args.id}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.V2Partner>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  deletePartner(
    args: {
      id: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2Empty> {
    const path = `/v2/partner/${args.id}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.V2Empty>('DELETE', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  patchPartner(
    args: {
      id: string,
      body: models.V2PatchPartnerRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2Partner> {
    const path = `/v2/partner/${args.id}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.V2Partner>('PATCH', path, options, JSON.stringify(args.body));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  getPartnerStatistics(
    args: {
      from?: string,
      to?: string,
      partnerId?: string,
      group?: boolean,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2GetPartnerStatisticsResponse> {
    const path = `/v2/statistics/partner`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('from' in args) {
      options.params = options.params.set('from', String(args.from));
    }
    if ('to' in args) {
      options.params = options.params.set('to', String(args.to));
    }
    if ('partnerId' in args) {
      options.params = options.params.set('partnerId', String(args.partnerId));
    }
    if ('group' in args) {
      options.params = options.params.set('group', String(args.group));
    }
    return this.sendRequest<models.V2GetPartnerStatisticsResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  getWarningStatistics(
    args: {
      from?: string,
      to?: string,
      partnerId?: string,
      group?: boolean,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2GetWarningStatisticsResponse> {
    const path = `/v2/statistics/warnings`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    if ('from' in args) {
      options.params = options.params.set('from', String(args.from));
    }
    if ('to' in args) {
      options.params = options.params.set('to', String(args.to));
    }
    if ('partnerId' in args) {
      options.params = options.params.set('partnerId', String(args.partnerId));
    }
    if ('group' in args) {
      options.params = options.params.set('group', String(args.group));
    }
    return this.sendRequest<models.V2GetWarningStatisticsResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  cloudTaskHandler(
    args: {
      body: models.V2CloudTaskRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2Empty> {
    const path = `/v2/taskHandler`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.V2Empty>('POST', path, options, JSON.stringify(args.body));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  version(
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2VersionResponse> {
    const path = `/v2/version`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.V2VersionResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  createWarning(
    args: {
      body: models.V2CreateWarningRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2CreateWarningResponse> {
    const path = `/v2/warning`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.V2CreateWarningResponse>('POST', path, options, JSON.stringify(args.body));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  getWarningTypes(
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2WarningTypes> {
    const path = `/v2/warning/type`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.V2WarningTypes>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  cancelWarning(
    args: {
      id: string,
      body: models.V2IdRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2Empty> {
    const path = `/v2/warning/${args.id}/cancel`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.V2Empty>('POST', path, options, JSON.stringify(args.body));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  getUsers(
      args: {},
      requestHttpOptions?: HttpOptions
  ): Observable<models.V2GetUsersResponse> {
    const path = `/v2/user`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };


    return this.sendRequest<models.V2GetUsersResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  patchUser(
      args: {
        id: string,
        role: string,
      },
      requestHttpOptions?: HttpOptions
  ): Observable<models.V2User> {
    const path = `/v2/user/${args.id}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.V2User>('PATCH', path, options, JSON.stringify(args));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  getIntegration(
      args: { integrationId: string, templates: boolean },
      requestHttpOptions?: HttpOptions
  ): Observable<models.V2GetIntegrationResponse> {
    const path = `/v2/integration/${args.integrationId}?templates=${args.templates}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };
    return this.sendRequest<models.V2GetIntegrationResponse>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  getTemplateDetail(
      args: {
        id: string,
      },
      requestHttpOptions?: HttpOptions
  ): Observable<models.V2Template> {
    const path = `/v2/template/${args.id}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };
    return this.sendRequest<models.V2Template>('GET', path, options);
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  patchTemplate(
      args: {
        id: string,
        body: models.V2Template,
      },
      requestHttpOptions?: HttpOptions
  ): Observable<models.V2Template> {
    const path = `/v2/template/${args.id}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.V2Template>('PATCH', path, options, JSON.stringify(args.body));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  createTemplate(
      args: {
        body: models.V2Template,
      },
      requestHttpOptions?: HttpOptions
  ): Observable<models.V2Template> {
    const path = `/v2/template`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<models.V2Template>('POST', path, options, JSON.stringify(args.body));
  }

  /**
   * Response generated for [ 200 ] HTTP response code.
   */
  deleteTemplate(
      args: {
        id: string,
      },
      requestHttpOptions?: HttpOptions
  ): Observable<string> {
    const path = `/v2/template/${args.id}`;
    const options: APIHttpOptions = {
      ...this.options,
      ...requestHttpOptions,
    };

    return this.sendRequest<string>('DELETE', path, options);
  }

  private sendRequest<T>(method: string, path: string, options: HttpOptions, body?: any): Observable<T> {
    switch (method) {
      case 'DELETE':
        return this.http.delete<T>(`${this.domain}${path}`, options);
      case 'GET':
        return this.http.get<T>(`${this.domain}${path}`, options);
      case 'HEAD':
        return this.http.head<T>(`${this.domain}${path}`, options);
      case 'OPTIONS':
        return this.http.options<T>(`${this.domain}${path}`, options);
      case 'PATCH':
        return this.http.patch<T>(`${this.domain}${path}`, body, options);
      case 'POST':
        return this.http.post<T>(`${this.domain}${path}`, body, options);
      case 'PUT':
        return this.http.put<T>(`${this.domain}${path}`, body, options);
      default:
        console.error(`Unsupported request: ${method}`);
        return throwError(`Unsupported request: ${method}`);
    }
  }
}

import { Action, createReducer, on } from '@ngrx/store';
import { V2Partner } from '../../../api/models';
import {
  EntityStateModel,
  INITIAL_ENTITY_STATE,
  StateStatus,
} from '../common.model';
import { createEntityAdapter } from '@ngrx/entity';
import {
  deletePartnerFailureAction,
  deletePartnerRequestAction,
  deletePartnerSuccessAction,
  getPartnerDetailsSuccessAction,
  getPartnersFailureAction,
  getPartnersRequestAction,
  getPartnersSuccessAction,
} from '../actions/partners.actions';

export type PartnersState = EntityStateModel<V2Partner>;

const initialState: PartnersState = { ...INITIAL_ENTITY_STATE };

export const partnersAdapter = createEntityAdapter<V2Partner>();

const reducer = createReducer(
  initialState,
  on(getPartnersRequestAction, deletePartnerRequestAction, (state, {}) => ({
    ...state,
    status: StateStatus.LOADING,
  })),
  on(getPartnersSuccessAction, (state, { partners }) => ({
    ...state,
    ...partnersAdapter.setAll(partners, state),
    status: StateStatus.LOADED,
  })),
  on(getPartnerDetailsSuccessAction, (state, { partner }) => ({
    ...state,
    ...partnersAdapter.upsertOne(partner, state),
  })),
  on(deletePartnerSuccessAction, (state, { id }) => ({
    ...state,
    ...partnersAdapter.removeOne(id, state),
  })),
  on(
    getPartnersFailureAction,
    deletePartnerFailureAction,
    (state, { error }) => ({
      ...state,
      error,
      status: StateStatus.ERROR,
    }),
  ),
);

export function partnersReducer(
  state: PartnersState,
  action: Action,
): PartnersState {
  return reducer(state, action);
}

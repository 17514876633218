import { AppState } from '../reducers';
import { createSelector } from '@ngrx/store';
import { DAY_IN_SECONDS } from '../../app.constants';

export const $warningsState = ({ warnings }: AppState) => warnings;

export const $warnings = createSelector($warningsState, ({ list }) => list);

export const $latestWarnings = createSelector($warningsState, ({ list }) => {
  const midnight = new Date().setHours(0, 0, 0, 0);
  return list.filter(w => parseInt(w.to, 10) > midnight / 1000);
});

export const $warningsTableData = createSelector($warnings, warnings =>
  warnings.map(warning => ({
    ...warning,
  })),
);

export const $lastWarning = createSelector(
  $warningsState,
  ({ lastWarningRef }): number => lastWarningRef,
);

export const $isWarningListFinal = createSelector(
  $warningsState,
  ({ isListFinal }): boolean => isListFinal,
);

export const $country = createSelector(
  $warningsState,
  ({ country }) => country,
);

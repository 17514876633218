import { EntityState } from '@ngrx/entity';

export enum StateStatus {
  START = 'uninitialized',
  LOADING = 'loading',
  ERROR = 'error',
  LOADED = 'loaded',
  UPDATING = 'updating',
}

export interface EntityStateModel<T, U = void> extends EntityState<T> {
  error: Error | null;
  status: StateStatus;
  metadata?: U;
  isListFinal: boolean;
}

export const INITIAL_ENTITY_STATE = {
  error: null,
  status: StateStatus.START,
  ids: [],
  entities: {},
  isListFinal: false,
};

import {
  V2CreateWarningRequest,
  V2CreateWarningResponse,
} from '../../../api/models';
import { createAction, props } from '@ngrx/store';

export const createDryRunWarningRequestAction = createAction(
  'CreateDryRunWarningRequestAction',
  props<{ warningRequest: V2CreateWarningRequest }>(),
);
export const createDryRunWarningSuccessAction = createAction(
  'CreateDryRunWarningSuccessAction',
  props<{ warning: V2CreateWarningResponse }>(),
);
export const createDryRunWarningFailureAction = createAction(
  'CreateDryRunWarningFailureAction',
  props<{ error: Error }>(),
);

export const createWarningRequestAction = createAction(
  'CreateWarningRequestAction',
  props<{ warningRequest: V2CreateWarningRequest }>(),
);
export const createWarningSuccessAction = createAction(
  'CreateWarningSuccessAction',
  props<{
    warning: V2CreateWarningResponse;
    warningRequest: V2CreateWarningRequest;
  }>(),
);
export const createWarningFailureAction = createAction(
  'CreateWarningFailureAction',
  props<{ error: Error }>(),
);

export const resetDryWarningStateAction = createAction(
  'ResetDryWarningStateAction',
);

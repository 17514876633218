import { StateStatus } from '../common.model';
import { Action, createReducer, on } from '@ngrx/store';
import { V2PartnerInvoice, V2WarningInvoice } from '../../../api/models';
import {
  getPartnerInvoicingDetailFailureAction,
  getPartnerInvoicingDetailRequestAction,
  getPartnerInvoicingDetailSuccessAction,
  getPartnerInvoicingFailureAction,
  getPartnerInvoicingRequestAction,
  getPartnerInvoicingSuccessAction,
} from '../actions/invoicing.actions';

export interface InvoicingState {
  partners: V2PartnerInvoice[];
  warnings: V2WarningInvoice[];
  detailStatus: StateStatus;
}

const initialState: InvoicingState = {
  warnings: [],
  partners: [],
  detailStatus: StateStatus.START,
};

const reducer = createReducer(
  initialState,
  on(getPartnerInvoicingRequestAction, state => ({
    ...state,
  })),
  on(getPartnerInvoicingDetailRequestAction, state => ({
    ...state,
    detailStatus: StateStatus.LOADING,
  })),
  on(getPartnerInvoicingSuccessAction, (state, { invoicing }) => ({
    ...state,
    partners: invoicing,
  })),
  on(getPartnerInvoicingDetailSuccessAction, (state, { warnings }) => ({
    ...state,
    warnings,
    detailStatus: StateStatus.LOADED,
  })),
  on(getPartnerInvoicingFailureAction, (state, { error }) => ({
    ...state,
    error,
  })),
  on(getPartnerInvoicingDetailFailureAction, (state, { error }) => ({
    ...state,
    error,
    detailStatus: StateStatus.ERROR,
  })),
);

export function invoicingReducer(
  state: InvoicingState,
  action: Action,
): InvoicingState {
  return reducer(state, action);
}

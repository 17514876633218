import { ECalendarValue, IDatePickerConfig } from 'ng2-date-picker';
import { MapPosition } from './models/map-position.model';
import { V2Country } from '../api/models';

export enum RoutePath {
  Landing = 'landing',
  Platform = 'platform',
  CreateWarning = 'create-warning',
  WarningDetail = 'warning-detail',
  ClientList = 'client-list',
  ClientDetail = 'client-detail',
  Invoicing = 'invoicing',
  PartnerList = 'partner-list',
  PartnerDetail = 'partner-detail',
  Statistics = 'statistics',
  StatisticsPartner = 'partner',
  StatisticsWarning = 'warning',
  WarningList = 'warning-list',
  LanguageDefault = 'cs',
  Unauthorized = 'unauthorized',
  UsersList = 'users-list',
  Templates = 'templates',
  TemplateDetail = 'template-detail',
  CreateTemplate = 'create-template',
  IntegrationDetail = 'integration-detail',
}

export const APP_ICONS: { iconName: string; iconPath: string }[] = [];

export const DATE_TIME_PICKER_CONFIG: IDatePickerConfig = {
  firstDayOfWeek: 'su',
  monthFormat: 'MMM, YYYY',
  disableKeypress: false,
  allowMultiSelect: false,
  closeOnSelect: undefined,
  closeOnSelectDelay: 100,
  openOnFocus: false,
  openOnClick: false,
  onOpenDelay: 0,
  weekDayFormat: 'ddd',
  appendTo: document.body,
  showNearMonthDays: true,
  showWeekNumbers: false,
  enableMonthSelector: true,
  yearFormat: 'YYYY',
  showGoToCurrent: true,
  dayBtnFormat: 'DD',
  monthBtnFormat: 'MMM',
  hours12Format: 'hh',
  hours24Format: 'HH',
  meridiemFormat: 'A',
  minutesFormat: 'mm',
  minutesInterval: 1,
  secondsFormat: 'ss',
  secondsInterval: 1,
  showSeconds: false,
  showTwentyFourHours: true,
  timeSeparator: ':',
  multipleYearsNavigateBy: 10,
  showMultipleYearsNavigation: false,
  locale: 'cs',
  hideInputContainer: false,
  returnedValueType: ECalendarValue.Moment,
  unSelectOnClick: true,
  hideOnOutsideClick: true,
  format: 'DD.MM YYYY - HH:mm',
};

export const czechPosition: MapPosition = {
  id: V2Country.CZ,
  lat: 50,
  lng: 15,
  zoom: 7,
  minimapZoom: 6,
  southWestBound: {
    lat: 53,
    lng: 7,
  },
  northEastBound: {
    lat: 46,
    lng: 25,
  },
};

export const countryCoordinates = {
  cz: {
    id: V2Country.CZ,
    topLeft: {
      lat: 51.2,
      lon: 11.7,
    },
    bottomRight: {
      lat: 48.45,
      lon: 19.3,
    },
  },
  sk: {
    id: V2Country.SK,
    topLeft: {
      lat: 50.25,
      lon: 16.5,
    },
    bottomRight: {
      lat: 47.3,
      lon: 23,
    },
  },
};

export const slovakPosition: MapPosition = {
  id: V2Country.SK,
  lat: 48.716337032605665,
  lng: 19.725618196344524,
  zoom: 7,
  minimapZoom: 6,
  southWestBound: {
    lat: 52,
    lng: 11,
  },
  northEastBound: {
    lat: 44,
    lng: 30,
  },
};

export const COUNTRIES = {
  CZ: czechPosition,
  SK: slovakPosition,
};

export enum GraphColors {
  CLIENTS = '#3F51B5',
  CREATED = '#4B8221',
  WARNINGS = '#C17257',
}

export enum MapTiles {
  BASIC = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  GEOLOGICAL = 'https://mapycz.b-cdn.net/zemepis-m/{z}-{x}-{y}',
  CZ_SK = 'https://outdoor.tiles.freemap.sk/{z}/{x}/{y}@2x',
}

export enum DateFormats {
  DAY_MONTH_YEAR_TIME = 'DD.MM YYYY HH:mm',
  DAY_MONTH_YEAR = 'DD.MM YYYY',
  MONTH_YEAR = 'MMMM YYYY',
}

export const FIVE_MINUTES = 5;
export const SIXTY = 60;
export const HOUR_FIVE_MINUTES = 65;

export const WARNINGS_PER_REQUEST = 100;
export const CLIENTS_PER_REQUEST = 50;
export const USERS_PER_REQUEST = 50;
export const TEMPLATES_PER_REQUEST = 50;

export const FIREBASE_NEW_USER_DELAY = 2000;

export const MIN_WARNING_DURATION_MINUTES = 10;
export const MAX_WARNING_DURATION_MINUTES = 4320;

export const MAX_DISTANCE_FROM_NOW_MINUTES = 2880;

export const DAY_IN_SECONDS = 86400;

export const DRAW_HISTORY_BYTE_LIMIT = 5000000;

export const SNACKBAR_DELAY = 3000;

export const highlightStyleActual = {
  color: '#1152d9',
  fillColor: '#2d52ad',
};

export const normalStyleActual = {
  color: '#306cd0',
  fillColor: '#6282c1',
};

export const highlightStyleOld = {
  color: '#615f5f',
  fillColor: '#7f7d7d',
};

export const normalStyleOld = {
  color: '#8b8b8b',
  fillColor: '#b4b4b4',
};

export const TEST_LOCATIONS = {
  CZ: {
    lat: 51.0545419,
    lon: 13.3710592,
  },
  SK: {
    lat: 49.79,
    lon: 21.175,
  },
};

export const USER_ROLES = [
  'role:meteorologist',
  'role:analyst',
  'role:manager',
  'role:admin',
  'role:disabled',
];

import { AppState } from '../reducers';
import { createSelector } from '@ngrx/store';
import { usersAdapter } from '../reducers/users.reducer';

const { selectAll: selectUsers } = usersAdapter.getSelectors();

export const $usersState = ({ users }: AppState) => users;

export const $users = createSelector($usersState, selectUsers);

export const $usersLoadedCount = createSelector(
  $users,
  ({ length }): number => length,
);

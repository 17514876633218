/* eslint-disable */

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DefaultHttpOptions, HttpOptions } from './';
import { USE_DOMAIN, USE_HTTP_OPTIONS, APIClient } from './api-client.service';

import * as models from './models';
import * as guards from './guards';

/**
 * Created with https://github.com/flowup/api-client-generator
 */
@Injectable()
export class GuardedAPIClient extends APIClient {

  constructor(readonly httpClient: HttpClient,
              @Optional() @Inject(USE_DOMAIN) domain?: string,
              @Optional() @Inject(USE_HTTP_OPTIONS) options?: DefaultHttpOptions) {
    super(httpClient, domain, options);
  }

  warningDummyEndpoint(
    args: {
      body: models.V2Warning,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2Warning> {
    return super.warningDummyEndpoint(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isV2Warning(res) || console.error(`TypeGuard for response 'V2Warning' caught inconsistency.`, res)));
  }

  setRole(
    args: {
      body: models.V2RoleRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2Empty> {
    return super.setRole(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isV2Empty(res) || console.error(`TypeGuard for response 'V2Empty' caught inconsistency.`, res)));
  }

  getClients(
    args: {
      id?: string,
      uid?: number,
      givenName?: string,
      surname?: string,
      zip?: string,
      country?: 'UNKNOWN_COUNTRY' | 'CZ' | 'SK' | 'GLOBAL',
      test?: boolean,
      partnerId?: string,
      limit?: number,
      offset?: number,
      orderBy?: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2GetClientsResponse> {
    return super.getClients(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isV2GetClientsResponse(res) || console.error(`TypeGuard for response 'V2GetClientsResponse' caught inconsistency.`, res)));
  }

  createClient(
    args: {
      body: models.V2CreateClientRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2Client> {
    return super.createClient(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isV2Client(res) || console.error(`TypeGuard for response 'V2Client' caught inconsistency.`, res)));
  }

  createClientContact(
    args: {
      clientId: string,
      body: models.V2CreateContactRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2Contact> {
    return super.createClientContact(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isV2Contact(res) || console.error(`TypeGuard for response 'V2Contact' caught inconsistency.`, res)));
  }

  deleteClientContact(
    args: {
      clientId: string,
      resourceId: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2Empty> {
    return super.deleteClientContact(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isV2Empty(res) || console.error(`TypeGuard for response 'V2Empty' caught inconsistency.`, res)));
  }

  createClientLocation(
    args: {
      clientId: string,
      body: models.V2CreateLocationRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2Location> {
    return super.createClientLocation(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isV2Location(res) || console.error(`TypeGuard for response 'V2Location' caught inconsistency.`, res)));
  }

  deleteClientLocation(
    args: {
      clientId: string,
      resourceId: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2Empty> {
    return super.deleteClientLocation(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isV2Empty(res) || console.error(`TypeGuard for response 'V2Empty' caught inconsistency.`, res)));
  }

  getClient(
    args: {
      id: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2Client> {
    return super.getClient(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isV2Client(res) || console.error(`TypeGuard for response 'V2Client' caught inconsistency.`, res)));
  }

  deleteClient(
    args: {
      id: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2Empty> {
    return super.deleteClient(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isV2Empty(res) || console.error(`TypeGuard for response 'V2Empty' caught inconsistency.`, res)));
  }

  patchClient(
    args: {
      id: string,
      body: models.V2PatchClientRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2Client> {
    return super.patchClient(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isV2Client(res) || console.error(`TypeGuard for response 'V2Client' caught inconsistency.`, res)));
  }

  getPartnersInvoice(
    args: {
      partnerId: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2GetPartnersInvoiceResponse> {
    return super.getPartnersInvoice(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isV2GetPartnersInvoiceResponse(res) || console.error(`TypeGuard for response 'V2GetPartnersInvoiceResponse' caught inconsistency.`, res)));
  }

  getWarningsInvoice(
    args: {
      from?: string,
      to?: string,
      partnerId?: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2GetWarningsInvoiceResponse> {
    return super.getWarningsInvoice(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isV2GetWarningsInvoiceResponse(res) || console.error(`TypeGuard for response 'V2GetWarningsInvoiceResponse' caught inconsistency.`, res)));
  }

  getPartners(
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2Partners> {
    return super.getPartners(requestHttpOptions)
      .pipe(tap((res: any) => guards.isV2Partners(res) || console.error(`TypeGuard for response 'V2Partners' caught inconsistency.`, res)));
  }

  createPartner(
    args: {
      body: models.V2CreatePartnerRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2Partner> {
    return super.createPartner(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isV2Partner(res) || console.error(`TypeGuard for response 'V2Partner' caught inconsistency.`, res)));
  }

  getPartner(
    args: {
      id: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2Partner> {
    return super.getPartner(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isV2Partner(res) || console.error(`TypeGuard for response 'V2Partner' caught inconsistency.`, res)));
  }

  deletePartner(
    args: {
      id: string,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2Empty> {
    return super.deletePartner(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isV2Empty(res) || console.error(`TypeGuard for response 'V2Empty' caught inconsistency.`, res)));
  }

  patchPartner(
    args: {
      id: string,
      body: models.V2PatchPartnerRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2Partner> {
    return super.patchPartner(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isV2Partner(res) || console.error(`TypeGuard for response 'V2Partner' caught inconsistency.`, res)));
  }

  getPartnerStatistics(
    args: {
      from?: string,
      to?: string,
      partnerId?: string,
      group?: boolean,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2GetPartnerStatisticsResponse> {
    return super.getPartnerStatistics(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isV2GetPartnerStatisticsResponse(res) || console.error(`TypeGuard for response 'V2GetPartnerStatisticsResponse' caught inconsistency.`, res)));
  }

  getWarningStatistics(
    args: {
      from?: string,
      to?: string,
      partnerId?: string,
      group?: boolean,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2GetWarningStatisticsResponse> {
    return super.getWarningStatistics(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isV2GetWarningStatisticsResponse(res) || console.error(`TypeGuard for response 'V2GetWarningStatisticsResponse' caught inconsistency.`, res)));
  }

  cloudTaskHandler(
    args: {
      body: models.V2CloudTaskRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2Empty> {
    return super.cloudTaskHandler(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isV2Empty(res) || console.error(`TypeGuard for response 'V2Empty' caught inconsistency.`, res)));
  }

  version(
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2VersionResponse> {
    return super.version(requestHttpOptions)
      .pipe(tap((res: any) => guards.isV2VersionResponse(res) || console.error(`TypeGuard for response 'V2VersionResponse' caught inconsistency.`, res)));
  }

  createWarning(
    args: {
      body: models.V2CreateWarningRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2CreateWarningResponse> {
    return super.createWarning(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isV2CreateWarningResponse(res) || console.error(`TypeGuard for response 'V2CreateWarningResponse' caught inconsistency.`, res)));
  }

  getWarningTypes(
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2WarningTypes> {
    return super.getWarningTypes(requestHttpOptions)
      .pipe(tap((res: any) => guards.isV2WarningTypes(res) || console.error(`TypeGuard for response 'V2WarningTypes' caught inconsistency.`, res)));
  }

  cancelWarning(
    args: {
      id: string,
      body: models.V2IdRequest,
    },
    requestHttpOptions?: HttpOptions
  ): Observable<models.V2Empty> {
    return super.cancelWarning(args, requestHttpOptions)
      .pipe(tap((res: any) => guards.isV2Empty(res) || console.error(`TypeGuard for response 'V2Empty' caught inconsistency.`, res)));
  }

}

import { V2Partner, V2PatchPartnerRequest } from '../../../api/models';
import { createAction, props } from '@ngrx/store';

export const getPartnersRequestAction = createAction(
  'GetPartnersRequestAction',
);
export const getPartnersSuccessAction = createAction(
  'GetPartnersSuccessAction',
  props<{ partners: V2Partner[] }>(),
);
export const getPartnersFailureAction = createAction(
  'GetPartnersFailureAction',
  props<{ error: Error }>(),
);

export const createPartnerRequestAction = createAction(
  'CreatePartnerRequestAction',
  props<{ partner: V2Partner }>(),
);
export const createPartnerSuccessAction = createAction(
  'CreatePartnerSuccessAction',
  props<{ partner: V2Partner }>(),
);
export const createPartnerFailureAction = createAction(
  'CreatePartnerFailureAction',
  props<{ error: Error }>(),
);

export const deletePartnerRequestAction = createAction(
  'DeletePartnerRequestAction',
  props<{ id: string }>(),
);
export const deletePartnerSuccessAction = createAction(
  'DeletePartnerSuccessAction',
  props<{ id: string }>(),
);
export const deletePartnerFailureAction = createAction(
  'DeletePartnerFailureAction',
  props<{ error: Error }>(),
);

export const getPartnerDetailsRequestAction = createAction(
  'GetPartnerDetailsRequestAction',
  props<{ id: string }>(),
);
export const getPartnerDetailsSuccessAction = createAction(
  'GetPartnerDetailsSuccessAction',
  props<{ partner: V2Partner }>(),
);
export const getPartnerDetailsFailureAction = createAction(
  'GetPartnerDetailsFailureAction',
  props<{ error: Error }>(),
);

export const patchPartnerRequestAction = createAction(
  'PatchPartnerRequestAction',
  props<{ partner: V2PatchPartnerRequest }>(),
);
export const patchPartnerSuccessAction = createAction(
  'PatchPartnerSuccessAction',
  props<{ partner: V2Partner }>(),
);
export const patchPartnerFailureAction = createAction(
  'PatchPartnerFailureAction',
  props<{ error: Error }>(),
);

import { createAction, props } from '@ngrx/store';
import firebase from 'firebase/compat';
import IdTokenResult = firebase.auth.IdTokenResult;

export const loginRequestAction = createAction('LoginRequestAction');
export const loginSuccessAction = createAction(
  'LoginSuccessAction',
  props<{ userInfo: IdTokenResult }>(),
);
export const loginFromLastSessionAction = createAction(
  'LoginFromLastSession',
  props<{ userInfo: IdTokenResult }>(),
);
export const loginFailureAction = createAction(
  'LoginFailureAction',
  props<{ error: Error }>(),
);

export const logoutRequestAction = createAction('LogoutRequestAction');
export const logoutSuccessAction = createAction('LogoutSuccessAction');
export const logoutFailureAction = createAction(
  'LogoutFailureAction',
  props<{ error: Error }>(),
);

import { createAction, props } from '@ngrx/store';
import { V2Warning } from '../../../api/models';

export const subscribeToWarningAction = createAction(
  'SubscribeToWarningAction',
);
export const unsubscribeFromWarningAction = createAction(
  'UnsubscribeFromWarningAction',
);
export const updateWarningDetailDataAction = createAction(
  'UpdateWarningDetailDataAction',
  props<{ warning: V2Warning }>(),
);

export const cancelWarningRequestAction = createAction(
  'CancelWarningRequestAction',
  props<{ id: string }>(),
);
export const cancelWarningSuccessAction = createAction(
  'CancelWarningSuccessAction',
  props<{ id: string }>(),
);
export const cancelWarningFailureAction = createAction(
  'CancelWarningFailureAction',
  props<{ error: Error }>(),
);

import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { APIClient } from '../../../api';
import { of } from 'rxjs';
import {
  addClientContactFailureAction,
  addClientContactRequestAction,
  addClientContactSuccessAction,
  addClientLocationFailureAction,
  addClientLocationRequestAction,
  addClientLocationSuccessAction,
  createClientFailureAction,
  createClientRequestAction,
  createClientSuccessAction,
  deleteClientContactFailureAction,
  deleteClientContactRequestAction,
  deleteClientContactSuccessAction,
  deleteClientFailureAction,
  deleteClientLocationFailureAction,
  deleteClientLocationRequestAction,
  deleteClientLocationSuccessAction,
  deleteClientRequestAction,
  deleteClientSuccessAction,
  getClientDetailsFailureAction,
  getClientDetailsRequestAction,
  getClientDetailsSuccessAction,
  getClientsFailureAction,
  getClientsRequestAction,
  getClientsSuccessAction,
  patchClientFailureAction,
  patchClientRequestAction,
  patchClientSuccessAction,
} from '../actions/clients.actions';
import { removeEmptyProperties } from '../../app.utils';
import { CLIENTS_PER_REQUEST, SNACKBAR_DELAY } from '../../app.constants';
import { AppState } from '../reducers';
import { select, Store } from '@ngrx/store';
import { $clientsLoadedCount } from '../selectors/clients.selectors';
import { ClientsFilters } from '../../models/clients-filters.model';
import { Translation, TRANSLATION } from '../../i18n/utils';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../../views/platform/create-warning/dialog/error-dialog/error-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InformationDialogComponent } from '../../components/information-dialog/information-dialog.component';

@Injectable()
export class ClientsEffects {
  getClients$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getClientsRequestAction),
      map(({ filter }) => removeEmptyProperties(filter)),
      withLatestFrom(this.store.pipe(select($clientsLoadedCount))),
      switchMap(([filter, offset]) =>
        this.api
          .getClients({
            limit: CLIENTS_PER_REQUEST,
            offset: (filter as ClientsFilters).clearPreviousResults
              ? 0
              : offset,
            ...filter,
          })
          .pipe(
            map(({ clients }) =>
              getClientsSuccessAction({
                clients,
                clearPreviousResults: (filter as ClientsFilters)
                  .clearPreviousResults,
              }),
            ),
            catchError(error => of(getClientsFailureAction({ error }))),
          ),
      ),
    ),
  );

  getClientDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getClientDetailsRequestAction),
      switchMap(({ id }) =>
        this.api.getClient({ id }).pipe(
          map(client => getClientDetailsSuccessAction({ client })),
          catchError(error => of(getClientDetailsFailureAction({ error }))),
        ),
      ),
    ),
  );

  deleteClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteClientRequestAction),
      switchMap(({ id }) =>
        this.api.deleteClient({ id }).pipe(
          map(() => deleteClientSuccessAction({ id })),
          catchError(error => of(deleteClientFailureAction({ error }))),
        ),
      ),
    ),
  );

  deleteClientSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteClientSuccessAction),
      map(({ id }) => getClientDetailsRequestAction({ id })),
    ),
  );

  createClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createClientRequestAction),
      switchMap(({ client: clientData, filter }) =>
        this.api
          .createClient({
            body: {
              uid: clientData.uid,
              givenName: clientData.givenName,
              surname: clientData.surname,
              partnerId: clientData.partnerId,
              test: clientData.test,
            },
          })
          .pipe(
            map(client => createClientSuccessAction({ client, filter })),
            catchError(error => of(createClientFailureAction({ error }))),
          ),
      ),
    ),
  );

  createClientFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createClientFailureAction),
        tap(error => {
          this.dialog.closeAll();
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            data: {
              // @ts-ignore
              error: error.error.error,
            },
          });
          return dialogRef.afterClosed();
        }),
      ),
    { dispatch: false },
  );

  clientCreated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createClientSuccessAction),
      //TODO: redirect to the edit/detail view
      map(({ filter }) => getClientsRequestAction({ filter })),
    ),
  );

  patchClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patchClientRequestAction),
      switchMap(({ client: clientData }) =>
        this.api.patchClient({ id: clientData.id, body: clientData }).pipe(
          map(client => patchClientSuccessAction({ client })),
          catchError(error => of(patchClientFailureAction({ error }))),
        ),
      ),
    ),
  );

  clientPatched$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patchClientSuccessAction),
      //TODO: redirect?
      map(({ client }) => getClientDetailsRequestAction({ id: client.id })),
    ),
  );

  clientPatchSuccessSnackBar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          patchClientSuccessAction,
          addClientContactSuccessAction,
          addClientLocationSuccessAction,
        ),
        tap(() => {
          this.snackBar.open(
            this.lang.clientDetail.saveSuccessTitle,
            this.lang.common.close,
            { duration: SNACKBAR_DELAY },
          );
        }),
      ),
    { dispatch: false },
  );

  addContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addClientContactRequestAction),
      switchMap(({ contact }) =>
        this.api
          .createClientContact({
            clientId: contact.clientId,
            body: contact,
          })
          .pipe(
            map(newContact =>
              addClientContactSuccessAction({ contact: newContact }),
            ),
            catchError(error => of(addClientContactFailureAction({ error }))),
          ),
      ),
    ),
  );

  deleteContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteClientContactRequestAction),
      switchMap(({ clientId, contactId }) =>
        this.api.deleteClientContact({ clientId, resourceId: contactId }).pipe(
          map(() => deleteClientContactSuccessAction({ clientId })),
          catchError(error => of(deleteClientContactFailureAction({ error }))),
        ),
      ),
    ),
  );

  contactAdded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addClientContactSuccessAction),
      map(({ contact }) =>
        getClientDetailsRequestAction({ id: contact.clientId }),
      ),
    ),
  );

  addContact$Failed = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addClientContactFailureAction),
        tap(() => {
          this.dialog.closeAll();
          const dialogRef = this.dialog.open(InformationDialogComponent, {
            data: {
              title: this.lang.common.error,
              message: this.lang.common.phoneError,
            },
          });
          return dialogRef.afterClosed();
        }),
      ),
    { dispatch: false },
  );

  contactDeleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteClientContactSuccessAction),
      map(({ clientId }) => getClientDetailsRequestAction({ id: clientId })),
    ),
  );

  addLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addClientLocationRequestAction),
      switchMap(({ location }) =>
        this.api
          .createClientLocation({
            clientId: location.clientId,
            body: location,
          })
          .pipe(
            map(storedLocation =>
              addClientLocationSuccessAction({ location: storedLocation }),
            ),
            catchError(error => of(addClientLocationFailureAction({ error }))),
          ),
      ),
    ),
  );

  deleteLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteClientLocationRequestAction),
      switchMap(({ clientId, locationId }) =>
        this.api
          .deleteClientLocation({ clientId, resourceId: locationId })
          .pipe(
            map(() => deleteClientLocationSuccessAction({ clientId })),
            catchError(error =>
              of(deleteClientLocationFailureAction({ error })),
            ),
          ),
      ),
    ),
  );

  locationAdded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addClientLocationSuccessAction),
      map(({ location }) =>
        getClientDetailsRequestAction({ id: location.clientId }),
      ),
    ),
  );

  locationDeleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteClientLocationSuccessAction),
      map(({ clientId }) => getClientDetailsRequestAction({ id: clientId })),
    ),
  );

  constructor(
    @Inject(TRANSLATION) public readonly lang: Translation,
    private readonly actions$: Actions,
    private readonly api: APIClient,
    private readonly store: Store<AppState>,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
  ) {}
}

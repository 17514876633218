import { createAction, props } from '@ngrx/store';
import { WarningFilters } from '../../models/warning-filters.model';
import { V2Country, V2Warning } from '../../../api/models';

export const getWarningsRequestAction = createAction(
  'GetWarningsRequestAction',
  props<{ filters: WarningFilters }>(),
);
export const getWarningsSuccessAction = createAction(
  'GetWarningsSuccessAction',
  props<{
    warnings: V2Warning[];
    clearPreviousResults: boolean;
    lastWarningRef: number | null;
  }>(),
);
export const getWarningsFailureAction = createAction(
  'GetWarningsFailureAction',
  props<{ error: Error }>(),
);

export const setCountryAction = createAction(
  'SetCountryAction',
  props<{ country: string }>(),
);

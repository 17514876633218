import { AuthenticationEffects } from './authentication.effects';
import { RouteEffects } from './route.effects';
import { PartnersEffects } from './partners.effects';
import { ClientsEffects } from './clients.effects';
import { WarningTypesEffects } from './warning-types.effects';
import { WarningEffects } from './warning.effects';
import { WarningDetailEffects } from './warning-detail.effects';
import { WarningsEffects } from './warnings.effects';
import { StatisticsEffects } from './statistics.effects';
import { InvoicingEffects } from './invoicing.effects';
import { LayersEffects } from './layers.effects';
import { ClientsLocationsEffects } from './clientLocations.effects';
import { UsersEffects } from './users.effects';
import { TemplatesEffects } from './templates.effects';
import { IntegrationsEffects } from './integrations.effects';

export const effects = [
  AuthenticationEffects,
  RouteEffects,
  PartnersEffects,
  ClientsEffects,
  WarningTypesEffects,
  WarningEffects,
  WarningDetailEffects,
  WarningsEffects,
  StatisticsEffects,
  InvoicingEffects,
  LayersEffects,
  ClientsLocationsEffects,
  UsersEffects,
  TemplatesEffects,
  IntegrationsEffects,
];

import { Component, Inject } from '@angular/core';
import { Translation, TRANSLATION } from '../../../../../i18n/utils';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'met-error-dialog-dialog',
  templateUrl: 'error-dialog.html',
  styles: ['.content {padding: 1rem}'],
})
export class ErrorDialogComponent {
  warningLink = '';

  constructor(
    @Inject(TRANSLATION) public readonly lang: Translation,
    @Inject(MAT_DIALOG_DATA)
    public errorData: {
      error: Error;
    },
  ) {}
}

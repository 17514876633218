import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import {
  navigationEndNotifyAction,
  navigationStartNotifyAction,
  redirectToClientDetailAction,
  redirectToClientsListAction,
  redirectToCreateTemplateAction,
  redirectToIntegrationDetailAction,
  redirectToPartnerDetailAction,
  redirectToPartnerListAction,
  redirectToTemplateDetailAction,
  redirectToWarningDetailAction,
} from '../actions/route.actions';
import { RoutePath } from '../../app.constants';
import { AppState } from '../reducers';
import { select, Store } from '@ngrx/store';
import { $currentLanguage } from '../selectors/route.selectors';

@Injectable()
export class RouteEffects {
  constructor(
    private readonly router: Router,
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
  ) {}

  navigationStarted$ = createEffect(() =>
    this.router.events.pipe(
      filter(e => e instanceof NavigationStart),
      map(() => navigationStartNotifyAction()),
    ),
  );
  navigationFinished$ = createEffect(() =>
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(({ urlAfterRedirects }: NavigationEnd) => {
        const route = this.router.parseUrl(urlAfterRedirects);
        const pathSegments = route.root.children['primary'].segments.map(
          ({ path }) => path,
        );
        return navigationEndNotifyAction({
          route: {
            pathSegments,
            params: route.queryParams,
          },
        });
      }),
    ),
  );

  clientDetailRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(redirectToClientDetailAction),
        withLatestFrom(this.store.pipe(select($currentLanguage))),
        tap(([{ userId }, language]) =>
          this.router.navigate(
            [language, RoutePath.Platform, RoutePath.ClientDetail],
            { queryParams: { userId } },
          ),
        ),
      ),
    { dispatch: false },
  );

  clientsListRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(redirectToClientsListAction),
        withLatestFrom(this.store.pipe(select($currentLanguage))),
        tap(([, language]) =>
          this.router.navigate([
            language,
            RoutePath.Platform,
            RoutePath.ClientList,
          ]),
        ),
      ),
    { dispatch: false },
  );

  partnerListRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(redirectToPartnerListAction),
        withLatestFrom(this.store.pipe(select($currentLanguage))),
        tap(([, language]) =>
          this.router.navigate([
            language,
            RoutePath.Platform,
            RoutePath.PartnerList,
          ]),
        ),
      ),
    { dispatch: false },
  );

  partnerDetailRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(redirectToPartnerDetailAction),
        withLatestFrom(this.store.pipe(select($currentLanguage))),
        tap(([{ partnerId }, language]) =>
          this.router.navigate(
            [language, RoutePath.Platform, RoutePath.PartnerDetail],
            { queryParams: { partnerId } },
          ),
        ),
      ),
    { dispatch: false },
  );

  warningDetailRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(redirectToWarningDetailAction),
        withLatestFrom(this.store.pipe(select($currentLanguage))),
        tap(([{ warningId }, language]) =>
          this.router.navigate(
            [language, RoutePath.Platform, RoutePath.WarningDetail],
            { queryParams: { warningId } },
          ),
        ),
      ),
    { dispatch: false },
  );

  templateDetailRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(redirectToTemplateDetailAction),
        withLatestFrom(this.store.pipe(select($currentLanguage))),
        tap(([{ templateId }, language]) =>
          this.router.navigate(
            [language, RoutePath.Platform, RoutePath.WarningDetail],
            { queryParams: { templateId } },
          ),
        ),
      ),
    { dispatch: false },
  );

  integrationDetailRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(redirectToIntegrationDetailAction),
        withLatestFrom(this.store.pipe(select($currentLanguage))),
        tap(([{ integrationId }, language]) => {
          this.router.navigate(
            [language, RoutePath.Platform, RoutePath.IntegrationDetail],
            { queryParams: { integrationId } },
          );
        }),
      ),
    { dispatch: false },
  );

  createTemplateRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(redirectToCreateTemplateAction),
        withLatestFrom(this.store.pipe(select($currentLanguage))),
        tap(([{ integrationId }, language]) => {
          this.router.navigate(
            [language, RoutePath.Platform, RoutePath.CreateTemplate],
            { queryParams: { integrationId } },
          );
        }),
      ),
    { dispatch: false },
  );
}

<h1 mat-dialog-title>{{ lang.createWarning.error }}</h1>
<div class="content" mat-dialog-content>
  <p>
    {{ lang.common.error }}
    <span *ngIf="errorData.error">: {{errorData.error.message}}</span>
  </p>
</div>
<div mat-dialog-actions>
  <button mat-dialog-close mat-stroked-button>
    {{ lang.common.close }}
  </button>
</div>

import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter } from '@ngrx/entity';
import {
  EntityStateModel,
  INITIAL_ENTITY_STATE,
  StateStatus,
} from '../common.model';
import { TEMPLATES_PER_REQUEST } from '../../app.constants';
import { V2Template } from '../../../api/models';
import {
  getTemplateDetailFailureAction,
  getTemplateDetailRequestAction,
  getTemplateDetailSuccessAction,
  getTemplatesFailureAction,
  getTemplatesRequestAction,
  getTemplatesSuccessAction,
} from '../actions/templates.actions';

export type TemplatesState = EntityStateModel<V2Template>;

const initialState: TemplatesState = { ...INITIAL_ENTITY_STATE };

export const templatesAdapter = createEntityAdapter<V2Template>();

const reducer = createReducer(
  initialState,
  on(getTemplatesRequestAction, getTemplateDetailRequestAction, state => ({
    ...state,
    status: StateStatus.LOADING,
  })),
  on(getTemplatesSuccessAction, (state, { templates }) => ({
    ...state,
    ...templatesAdapter.setAll(templates, state),
    status: StateStatus.LOADED,
    isListFinal: templates.length < TEMPLATES_PER_REQUEST,
  })),
  on(
    getTemplatesFailureAction,
    getTemplateDetailFailureAction,
    (state, { error }) => ({
      ...state,
      error,
      status: StateStatus.ERROR,
    }),
  ),
  on(getTemplateDetailSuccessAction, (state, { template }) => ({
    ...state,
    ...templatesAdapter.upsertOne(template, state),
    status: StateStatus.LOADED,
  })),
);

export function templatesReducer(
  state: TemplatesState,
  action: Action,
): TemplatesState {
  return reducer(state, action);
}

import { Action, createReducer, on } from '@ngrx/store';
import {
  V2CreateWarningRequest,
  V2CreateWarningResponse,
} from '../../../api/models';
import {
  createDryRunWarningFailureAction,
  createDryRunWarningRequestAction,
  createDryRunWarningSuccessAction,
  createWarningFailureAction,
  createWarningRequestAction,
  createWarningSuccessAction,
  resetDryWarningStateAction,
} from '../actions/warning.actions';

export interface DryWarningState {
  warningRequest: V2CreateWarningRequest | null;
  dryRunResult: V2CreateWarningResponse | null;
  loading: boolean;
  error: Error;
}

const initialState: DryWarningState = {
  warningRequest: null,
  dryRunResult: null,
  loading: false,
  error: null,
};

const reducer = createReducer(
  initialState,
  on(createDryRunWarningRequestAction, (state, { warningRequest }) => ({
    ...state,
    warningRequest,
    loading: true,
  })),
  on(createDryRunWarningSuccessAction, (state, { warning }) => ({
    ...state,
    dryRunResult: warning,
    loading: false,
    error: null,
  })),
  on(createDryRunWarningFailureAction, (state, { error }) => ({
    ...state,
    error: error,
    loading: false,
  })),
  on(resetDryWarningStateAction, (state, {}) => ({
    ...state,
    dryRunResult: null,
    warningRequest: null,
    loading: false,
  })),
  on(createWarningRequestAction, (state, {}) => ({
    ...state,
    loading: true,
  })),
  on(createWarningSuccessAction, (state, {}) => ({
    ...state,
    loading: false,
    error: null,
  })),
  on(createWarningFailureAction, (state, { error }) => ({
    ...state,
    loading: false,
    error: error,
  })),
);

export function warningReducer(
  state: DryWarningState,
  action: Action,
): DryWarningState {
  return reducer(state, action);
}

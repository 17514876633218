import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TRANSLATION, Translation } from '../../i18n/utils';

@Component({
  selector: 'met-information-dialog',
  templateUrl: './information-dialog.component.html',
  styleUrls: ['./information-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationDialogComponent {
  constructor(
    @Inject(TRANSLATION) public readonly lang: Translation,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      message: string;
    },
  ) {}
}

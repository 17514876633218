import { createAction, props } from '@ngrx/store';

export const getActualLocationsRequestAction = createAction(
  'GetActualLocationsRequestAction',
  props<{ clientId: string }>(),
);
export const getActualLocationsSuccessAction = createAction(
  'GetActualLocationsSuccessAction',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props<{ locations: any; clientId: string }>(),
);
export const getActualLocationsFailureAction = createAction(
  'GetActualLocationsFailureAction',
  props<{ error: Error }>(),
);

export const removeLocationsAction = createAction('RemoveLocationsAction');

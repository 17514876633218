import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import moment from 'moment';
import { DateFormats } from './app.constants';

export const registerIconsFactory =
  (registry: MatIconRegistry, sanitizer: DomSanitizer) =>
  (icons: { iconName: string; iconPath: string }[]) => {
    // eslint-disable-next-line
    for (const icon of icons) {
      registry.addSvgIcon(
        icon.iconName,
        sanitizer.bypassSecurityTrustResourceUrl(icon.iconPath),
      );
    }
  };

export const groupByKey = (items, key) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {},
  );

export const removeEmptyProperties = (obj: {}) =>
  Object.entries(obj).reduce(
    (acc, [key, value]) =>
      value || typeof value === 'boolean' ? { ...acc, [key]: value } : acc,
    {},
  );

export function unixToDate(
  date: number,
  format: DateFormats = DateFormats.DAY_MONTH_YEAR_TIME,
): string {
  return moment(date * 1000).format(format);
}

import { createAction, props } from '@ngrx/store';

export const getMapLayerAction = createAction(
  'GetMapLayerAction',
  props<{ url: string; name: string; time: string; country: string }>(),
);
export const getMapLayerSuccessAction = createAction(
  'GetMapLayerSuccessAction',
  props<{ layer: {}; name: string; time: string; country: string }>(),
);
export const getMapLayerFailureAction = createAction(
  'GetMapLayerFailureAction',
  props<{ error: Error }>(),
);

<h1 mat-dialog-title class="title">{{ data.title }}</h1>
<div class="content" mat-dialog-content>
  <p class="message" *ngIf="data.message">
    {{ data.message }}
  </p>
</div>
<div mat-dialog-actions class="actions">
  <button mat-dialog-close mat-stroked-button>
    {{ lang.createWarning.close }}
  </button>
</div>

import { StateStatus } from '../common.model';
import { V2WarningType } from '../../../api/models';
import { Action, createReducer, on } from '@ngrx/store';
import {
  getWarningTypesFailureAction,
  getWarningTypesRequestAction,
  getWarningTypesSuccessAction,
} from '../actions/warning-types.actions';

export interface WarningTypesState {
  types: V2WarningType[];
  error: Error | null;
  status: StateStatus;
}

const initialState: WarningTypesState = {
  types: [],
  error: null,
  status: StateStatus.START,
};

const reducer = createReducer(
  initialState,
  on(getWarningTypesRequestAction, (state, {}) => ({
    ...state,
    status: StateStatus.LOADING,
  })),
  on(getWarningTypesSuccessAction, (state, { types }) => ({
    ...state,
    types,
    status: StateStatus.LOADED,
  })),
  on(getWarningTypesFailureAction, (state, { error }) => ({
    ...state,
    error,
    status: StateStatus.ERROR,
  })),
);

export function warningTypesReducer(
  state: WarningTypesState,
  action: Action,
): WarningTypesState {
  return reducer(state, action);
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { APIClient } from '../../../api';
import {
  getPartnerInvoicingDetailFailureAction,
  getPartnerInvoicingDetailRequestAction,
  getPartnerInvoicingDetailSuccessAction,
  getPartnerInvoicingFailureAction,
  getPartnerInvoicingRequestAction,
  getPartnerInvoicingSuccessAction,
} from '../actions/invoicing.actions';
import * as moment from 'moment';

@Injectable()
export class InvoicingEffects {
  getInvoicing$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPartnerInvoicingRequestAction),
      switchMap(({ partnerId }) =>
        this.api.getPartnersInvoice({ partnerId }).pipe(
          map(({ months }) =>
            getPartnerInvoicingSuccessAction({ invoicing: months }),
          ),
          catchError(error => of(getPartnerInvoicingFailureAction({ error }))),
        ),
      ),
    ),
  );

  getInvoicingDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPartnerInvoicingDetailRequestAction),
      map(({ partnerId, monthStart }) => ({
        partnerId,
        from: monthStart,
        to: moment
          .unix((monthStart as unknown) as number)
          .endOf('month')
          .unix()
          .toString(),
      })),
      switchMap(filter =>
        this.api.getWarningsInvoice({ ...filter }).pipe(
          map(({ warnings }) =>
            getPartnerInvoicingDetailSuccessAction({ warnings }),
          ),
          catchError(error =>
            of(getPartnerInvoicingDetailFailureAction({ error })),
          ),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly api: APIClient,
  ) {}
}

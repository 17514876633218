import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { APIClient } from '../../../api';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  getWarningTypesFailureAction,
  getWarningTypesRequestAction,
  getWarningTypesSuccessAction,
} from '../actions/warning-types.actions';
import { of } from 'rxjs';

@Injectable()
export class WarningTypesEffects {
  getWarningTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getWarningTypesRequestAction),
      switchMap(() =>
        this.api.getWarningTypes().pipe(
          map(({ types }) => getWarningTypesSuccessAction({ types })),
          catchError(error => of(getWarningTypesFailureAction({ error }))),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly api: APIClient,
  ) {}
}

import { ActionReducerMap } from '@ngrx/store';
import {
  authenticationReducer,
  AuthenticationState,
} from './authentication.reducer';
import { routeReducer, RouteState } from './route.reducer';
import { partnersReducer, PartnersState } from './partners.reducer';
import { clientsReducer, ClientsState } from './clients.reducer';
import {
  warningTypesReducer,
  WarningTypesState,
} from './warning-types.reducer';
import { DryWarningState, warningReducer } from './warning.reducer';
import {
  warningDetailReducer,
  WarningDetailState,
} from './warning-detail.reducer';
import { warningsReducer, WarningsState } from './warnings.reducer';
import { statisticsReducer, StatisticsState } from './statistics.reducer';
import { invoicingReducer, InvoicingState } from './invoicing.reducer';
import { layersReducer, LayersState } from './layers.reducer';
import {
  clientLocationsReducer,
  ClientLocationsState,
} from './actualLocations.reducer';
import { usersReducer, UsersState } from './users.reducer';
import { templatesReducer, TemplatesState } from './templates.reducer';
import { integrationsReducer, IntegrationsState } from './integrations.reducer';

export interface AppState {
  authentication: AuthenticationState;
  route: RouteState;
  partners: PartnersState;
  clients: ClientsState;
  warningTypes: WarningTypesState;
  dryWarning: DryWarningState;
  warningDetail: WarningDetailState;
  statistics: StatisticsState;
  warnings: WarningsState;
  invoicing: InvoicingState;
  layers: LayersState;
  clientLocations: ClientLocationsState;
  users: UsersState;
  templates: TemplatesState;
  integrations: IntegrationsState;
}
export const reducers: ActionReducerMap<AppState> = {
  authentication: authenticationReducer,
  route: routeReducer,
  partners: partnersReducer,
  clients: clientsReducer,
  warningTypes: warningTypesReducer,
  dryWarning: warningReducer,
  warningDetail: warningDetailReducer,
  statistics: statisticsReducer,
  warnings: warningsReducer,
  invoicing: invoicingReducer,
  layers: layersReducer,
  clientLocations: clientLocationsReducer,
  users: usersReducer,
  templates: templatesReducer,
  integrations: integrationsReducer,
};

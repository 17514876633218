import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { AppState } from './store/reducers';
import { Store } from '@ngrx/store';
import { $navigationFinished } from './store/selectors/route.selectors';
import { registerIconsFactory } from './app.utils';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { APP_ICONS } from './app.constants';

@Component({
  selector: 'met-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  readonly navigationFinished$: Observable<boolean> = this.store.select(
    $navigationFinished,
  );

  constructor(
    private readonly store: Store<AppState>,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly sanitizer: DomSanitizer,
  ) {
    registerIconsFactory(this.matIconRegistry, this.sanitizer)(APP_ICONS);
  }
}

import { V2WarningType } from '../../../api/models';
import { createAction, props } from '@ngrx/store';

export const getWarningTypesRequestAction = createAction(
  'GetWarningTypesRequestAction',
);
export const getWarningTypesSuccessAction = createAction(
  'GetWarningTypesSuccessAction',
  props<{ types: V2WarningType[] }>(),
);
export const getWarningTypesFailureAction = createAction(
  'GetWarningTypesFailureAction',
  props<{ error: Error }>(),
);

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { APIClient } from '../../../api';
import { catchError, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import {
  cancelWarningFailureAction,
  cancelWarningRequestAction,
  cancelWarningSuccessAction,
  subscribeToWarningAction,
  unsubscribeFromWarningAction,
  updateWarningDetailDataAction,
} from '../actions/warning-detail.acitons';
import { AppState } from '../reducers';
import { select, Store } from '@ngrx/store';
import { $selectedWarningId } from '../selectors/route.selectors';
import { of } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable()
export class WarningDetailEffects {
  subscribeWarningDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(subscribeToWarningAction),
      switchMap(() =>
        this.store.pipe(
          select($selectedWarningId),
          filter(id => !!id),
        ),
      ),
      switchMap(id =>
        this.firestore
          .collection('warnings')
          .doc(id)
          .valueChanges()
          .pipe(
            takeUntil(this.actions$.pipe(ofType(unsubscribeFromWarningAction))),
            map(warning => updateWarningDetailDataAction({ warning })),
          ),
      ),
    ),
  );

  cancelWarning$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cancelWarningRequestAction),
      switchMap(({ id }) =>
        this.api.cancelWarning({ id, body: { id } }).pipe(
          map(() => cancelWarningSuccessAction({ id })),
          catchError(error => of(cancelWarningFailureAction({ error }))),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly api: APIClient,
    private readonly store: Store<AppState>,
    private readonly firestore: AngularFirestore,
  ) {}
}

import { InjectionToken } from '@angular/core';
import { cs } from './cs.translation';

export const LANG_URL_LENGTH = 3;

export enum WebsiteLanguage {
  English = 'en',
  Czech = 'cs',
}

export type Translation = typeof cs;

export const TRANSLATION = new InjectionToken<Translation>('TRANSLATION');

import { partnersAdapter } from '../reducers/partners.reducer';
import { AppState } from '../reducers';
import { createSelector } from '@ngrx/store';
import { $selectedPartnerId } from './route.selectors';

const { selectAll: selectPartners } = partnersAdapter.getSelectors();

export const $partnersState = ({ partners }: AppState) => partners;

export const $partners = createSelector($partnersState, selectPartners);

export const $partnerNames = createSelector($partners, partners =>
  partners.reduce((acc, curr) => ({ ...acc, [curr.id]: curr.name }), {}),
);

export const $partnerSelectOptions = createSelector($partners, partners =>
  partners.map(partner => ({ id: partner.id, name: partner.name })),
);

export const $selectPartnerById = id =>
  createSelector($partnersState, partners => partners.entities[id]);

export const $selectedPartner = createSelector(
  $partnersState,
  $selectedPartnerId,
  (partners, id) => partners.entities[id],
);

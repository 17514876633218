import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Translation, TRANSLATION } from '../../i18n/utils';
import {
  getActualLocationsFailureAction,
  getActualLocationsRequestAction,
  getActualLocationsSuccessAction,
} from '../actions/actualLocations.actions';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable()
export class ClientsLocationsEffects {
  getActualLocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getActualLocationsRequestAction),
      switchMap(({ clientId }) =>
        this.firestore
          .collection('actualLocations')
          .doc(clientId)
          .snapshotChanges()
          .pipe(
            map(locations => {
              if (locations.payload.data()) {
                return getActualLocationsSuccessAction({
                  locations: {
                    ...(locations.payload.data() as {}),
                  },
                  clientId,
                });
              } else {
                return getActualLocationsFailureAction({
                  error: {
                    name: 'locations Error',
                    message: 'no active situation',
                  },
                });
              }
            }),
            catchError(({ error }) =>
              of(getActualLocationsFailureAction({ error })),
            ),
          ),
      ),
    ),
  );

  constructor(
    @Inject(TRANSLATION) public readonly lang: Translation,
    private readonly actions$: Actions,
    private readonly firestore: AngularFirestore,
  ) {}
}

import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter } from '@ngrx/entity';
import {
  EntityStateModel,
  INITIAL_ENTITY_STATE,
  StateStatus,
} from '../common.model';
import { USERS_PER_REQUEST } from '../../app.constants';
import { V2User } from '../../../api/models';
import {
  getUsersFailureAction,
  getUsersRequestAction,
  getUsersSuccessAction,
} from '../actions/users.actions';

export type UsersState = EntityStateModel<V2User>;

const initialState: UsersState = { ...INITIAL_ENTITY_STATE };

export const usersAdapter = createEntityAdapter<V2User>();

const reducer = createReducer(
  initialState,
  on(getUsersRequestAction, state => ({
    ...state,
    status: StateStatus.LOADING,
  })),
  on(getUsersSuccessAction, (state, { users }) => ({
    ...state,
    ...usersAdapter.setAll(users, state),
    status: StateStatus.LOADED,
    isListFinal: users.length < USERS_PER_REQUEST,
  })),
  on(getUsersFailureAction, (state, { error }) => ({
    ...state,
    error,
    status: StateStatus.ERROR,
  })),
);

export function usersReducer(state: UsersState, action: Action): UsersState {
  return reducer(state, action);
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { APIClient } from '../../../api';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  getFilteredPartnerStatisticsFailureAction,
  getFilteredPartnerStatisticsRequestAction,
  getFilteredPartnerStatisticsSuccessAction,
  getFilteredWarningStatisticsFailureAction,
  getFilteredWarningStatisticsRequestAction,
  getFilteredWarningStatisticsSuccessAction,
  getPartnerStatisticsFailureAction,
  getPartnerStatisticsRequestAction,
  getPartnerStatisticsSuccessAction,
  getWarningStatisticsFailureAction,
  getWarningStatisticsRequestAction,
  getWarningStatisticsSuccessAction,
} from '../actions/statistics.actions';
import { of } from 'rxjs';
import moment from 'moment';

@Injectable()
export class StatisticsEffects {
  getPartnerStatistics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPartnerStatisticsRequestAction),
      switchMap(() =>
        this.api
          .getPartnerStatistics({
            from: moment().subtract(1, 'month').unix().toString(),
            to: moment().unix().toString(),
          })
          .pipe(
            map(({ partners }) =>
              getPartnerStatisticsSuccessAction({ partners }),
            ),
            catchError(error =>
              of(getPartnerStatisticsFailureAction({ error })),
            ),
          ),
      ),
    ),
  );

  getFilteredPartnerStatistics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getFilteredPartnerStatisticsRequestAction),
      switchMap(({ filter }) =>
        this.api.getPartnerStatistics({ ...filter }).pipe(
          map(({ partners }) =>
            getFilteredPartnerStatisticsSuccessAction({ partners }),
          ),
          catchError(error =>
            of(getFilteredPartnerStatisticsFailureAction({ error })),
          ),
        ),
      ),
    ),
  );

  getWarningStatistics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getWarningStatisticsRequestAction),
      switchMap(() =>
        this.api
          .getWarningStatistics({
            from: moment().subtract(1, 'month').unix().toString(),
            to: moment().unix().toString(),
          })
          .pipe(
            map(({ warnings }) =>
              getWarningStatisticsSuccessAction({ warnings }),
            ),
            catchError(error =>
              of(getWarningStatisticsFailureAction({ error })),
            ),
          ),
      ),
    ),
  );

  getFilteredWarningStatistics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getFilteredWarningStatisticsRequestAction),
      switchMap(({ filter }) =>
        this.api.getWarningStatistics({ ...filter }).pipe(
          map(({ warnings }) =>
            getFilteredWarningStatisticsSuccessAction({ warnings }),
          ),
          catchError(error =>
            of(getFilteredWarningStatisticsFailureAction({ error })),
          ),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly api: APIClient,
  ) {}
}

import { createAction, props } from '@ngrx/store';
import { V2Integration } from '../../../api/models';

export const getIntegrationRequestAction = createAction(
  'GetIntegrationRequestAction',
  props<{ integrationId: string; templates: boolean }>(),
);

export const getIntegrationSuccessAction = createAction(
  'GetIntegrationSuccessAction',
  props<{ integration: V2Integration }>(),
);

export const getIntegrationFailureAction = createAction(
  'GetIntegrationFailureAction',
  props<{ error: Error }>(),
);

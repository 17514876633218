import { Action, createReducer, on } from '@ngrx/store';
import {
  subscribeToWarningAction,
  unsubscribeFromWarningAction,
  updateWarningDetailDataAction,
} from '../actions/warning-detail.acitons';
import { V2Warning } from '../../../api/models';

export enum ConnectionStatus {
  CONNECTED = 'connected',
  DISCONNECTED = 'disconnected',
}

export interface WarningDetailState {
  status: ConnectionStatus;
  warning: V2Warning | null;
}

const initialState: WarningDetailState = {
  status: ConnectionStatus.DISCONNECTED,
  warning: null,
};

const reducer = createReducer(
  initialState,
  on(subscribeToWarningAction, state => ({
    ...state,
    status: ConnectionStatus.CONNECTED,
  })),
  on(unsubscribeFromWarningAction, (state, {}) => ({
    ...state,
    status: ConnectionStatus.DISCONNECTED,
    warning: null,
  })),
  on(updateWarningDetailDataAction, (state, { warning }) => ({
    ...state,
    warning: warning || null,
  })),
);

export function warningDetailReducer(
  state: WarningDetailState,
  action: Action,
): WarningDetailState {
  return reducer(state, action);
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { StoreModule } from '@ngrx/store';
import { reducers } from './store/reducers';
import { EffectsModule } from '@ngrx/effects';
import { effects } from './store/effects';
import { AuthService } from './services/auth.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APIClientModule } from '../api';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { LoadingModule } from './components/loading/loading.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TRANSLATION } from './i18n/utils';
import { cs } from './i18n/cs.translation';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { WarningSentComponentModule } from './views/platform/create-warning/dialog/warning-sent/warning-sent.module';
import { ErrorDialogComponentModule } from './views/platform/create-warning/dialog/error-dialog/error-dialog.module';
import { InformationDialogModule } from './components/information-dialog/information-dialog.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';

@NgModule({
  declarations: [
    AppComponent,
    // WarningSentComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    APIClientModule.forRoot({
      domain: environment.beURL,
      guardResponses: !environment.production,
    }),
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    EffectsModule.forRoot(effects),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    LoadingModule,
    FlexLayoutModule,
    MatDialogModule,
    WarningSentComponentModule,
    ErrorDialogComponentModule,
    InformationDialogModule,
    MatSnackBarModule,
  ],
  providers: [
    AuthService,
    {
      provide: MatDialogRef,
      useValue: {},
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    { provide: TRANSLATION, useValue: cs },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}

import { createAction, props } from '@ngrx/store';
import { V2User } from '../../../api/models';

export const getUsersRequestAction = createAction('GetUsersRequestAction');

export const getUsersSuccessAction = createAction(
  'GetUsersSuccessAction',
  props<{ users: V2User[] }>(),
);

export const getUsersFailureAction = createAction(
  'GetUsersFailureAction',
  props<{ error: Error }>(),
);

export const patchUserRequestAction = createAction(
  'PatchUserRequestAction',
  props<{ userId: string; role: string }>(),
);

export const patchUserSuccessAction = createAction(
  'PatchUserSuccessAction',
  props<{ user: V2User }>(),
);

export const patchUserFailureAction = createAction(
  'PatchUserFailureAction',
  props<{ error: Error }>(),
);

/* eslint-disable */

export enum WarningStatus {
  CREATED = "CREATED",
  CANCELED = "CANCELED",
  PROCESSING = "PROCESSING",
  EXTRACTED_CLIENTS = "EXTRACTED_CLIENTS",
  GENERATED_MESSAGES = "GENERATED_MESSAGES",
  SENDING = "SENDING",
  DONE = "DONE",
}

import { Action, createReducer, on } from '@ngrx/store';
import { StateStatus } from '../common.model';
import {
  getWarningsRequestAction,
  getWarningsSuccessAction,
  setCountryAction,
} from '../actions/warnings.actions';
import { V2Country, V2Warning } from '../../../api/models';
import { WARNINGS_PER_REQUEST } from '../../app.constants';
import { createWarningSuccessAction } from '../actions/warning.actions';

export interface WarningsState {
  list: V2Warning[];
  status: StateStatus;
  lastWarningRef: number | null;
  isListFinal: boolean;
  country: string;
}

const initialState: WarningsState = {
  list: [],
  status: StateStatus.LOADED,
  lastWarningRef: null,
  isListFinal: false,
  country: V2Country.CZ,
};

const reducer = createReducer(
  initialState,
  on(getWarningsRequestAction, state => ({
    ...state,
    status: StateStatus.LOADING,
  })),
  on(
    getWarningsSuccessAction,
    (state, { warnings, clearPreviousResults, lastWarningRef }) => ({
      ...state,
      list: clearPreviousResults ? warnings : [...state.list, ...warnings],
      lastWarningRef,
      isListFinal: warnings.length < WARNINGS_PER_REQUEST,
      status: StateStatus.LOADED,
    }),
  ),
  on(createWarningSuccessAction, (state, { warning, warningRequest }) => ({
    ...state,
    list: [...state.list, warningRequest],
  })),
  on(setCountryAction, (state, { country }) => ({
    ...state,
    country: country,
  })),
);

export function warningsReducer(
  state: WarningsState,
  action: Action,
): WarningsState {
  return reducer(state, action);
}

import { createAction, props } from '@ngrx/store';
import { V2PartnerInvoice, V2WarningInvoice } from '../../../api/models';

export const getPartnerInvoicingRequestAction = createAction(
  'GetPartnerInvoicingRequestAction',
  props<{ partnerId: string }>(),
);
export const getPartnerInvoicingSuccessAction = createAction(
  'GetPartnerInvoicingSuccessAction',
  props<{ invoicing: V2PartnerInvoice[] }>(),
);
export const getPartnerInvoicingFailureAction = createAction(
  'GetPartnerInvoicingFailureAction',
  props<{ error: Error }>(),
);

export const getPartnerInvoicingDetailRequestAction = createAction(
  'GetPartnerInvoicingDetailRequestAction',
  props<{ partnerId: string; monthStart: string }>(),
);
export const getPartnerInvoicingDetailSuccessAction = createAction(
  'GetPartnerInvoicingDetailSuccessAction',
  props<{ warnings: V2WarningInvoice[] }>(),
);
export const getPartnerInvoicingDetailFailureAction = createAction(
  'GetPartnerInvoicingDetailFailureAction',
  props<{ error: Error }>(),
);

import { V2Client } from '../../../api/models';
import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter } from '@ngrx/entity';
import {
  createClientSuccessAction,
  deleteClientFailureAction,
  deleteClientRequestAction,
  deleteClientSuccessAction,
  getClientDetailsFailureAction,
  getClientDetailsRequestAction,
  getClientDetailsSuccessAction,
  getClientsFailureAction,
  getClientsRequestAction,
  getClientsSuccessAction,
} from '../actions/clients.actions';
import {
  EntityStateModel,
  INITIAL_ENTITY_STATE,
  StateStatus,
} from '../common.model';
import { CLIENTS_PER_REQUEST } from '../../app.constants';

export type ClientsState = EntityStateModel<V2Client>;

const initialState: ClientsState = { ...INITIAL_ENTITY_STATE };

export const clientsAdapter = createEntityAdapter<V2Client>();

const reducer = createReducer(
  initialState,
  on(
    getClientsRequestAction,
    deleteClientRequestAction,
    getClientDetailsRequestAction,
    state => ({
      ...state,
      status: StateStatus.LOADING,
    }),
  ),
  on(getClientsSuccessAction, (state, { clients, clearPreviousResults }) => ({
    ...state,
    ...(clearPreviousResults
      ? clientsAdapter.setAll(clients, state)
      : clientsAdapter.addMany(clients, state)),
    status: StateStatus.LOADED,
    isListFinal: clients.length < CLIENTS_PER_REQUEST,
  })),
  on(getClientDetailsSuccessAction, (state, { client }) => ({
    ...state,
    ...clientsAdapter.upsertOne(client, state),
    status: StateStatus.LOADED,
  })),
  on(deleteClientSuccessAction, (state, { id }) => ({
    ...state,
    ...clientsAdapter.removeOne(id, state),
  })),
  on(
    getClientsFailureAction,
    deleteClientFailureAction,
    getClientDetailsFailureAction,
    (state, { error }) => ({
      ...state,
      error,
      status: StateStatus.ERROR,
    }),
  ),
  on(createClientSuccessAction, (state, { client }) => ({
    ...state,
    ...clientsAdapter.addOne(client, state),
  })),
);

export function clientsReducer(
  state: ClientsState,
  action: Action,
): ClientsState {
  return reducer(state, action);
}

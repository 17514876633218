import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { APIClient } from '../../../api';
import {
  createPartnerFailureAction,
  createPartnerRequestAction,
  createPartnerSuccessAction,
  deletePartnerFailureAction,
  deletePartnerRequestAction,
  deletePartnerSuccessAction,
  getPartnerDetailsFailureAction,
  getPartnerDetailsRequestAction,
  getPartnerDetailsSuccessAction,
  getPartnersFailureAction,
  getPartnersRequestAction,
  getPartnersSuccessAction,
  patchPartnerFailureAction,
  patchPartnerRequestAction,
  patchPartnerSuccessAction,
} from '../actions/partners.actions';
import { of } from 'rxjs';
import { Translation, TRANSLATION } from '../../i18n/utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SNACKBAR_DELAY } from '../../app.constants';

@Injectable()
export class PartnersEffects {
  getPartners$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPartnersRequestAction),
      switchMap(() =>
        this.api.getPartners().pipe(
          map(({ partners }) => getPartnersSuccessAction({ partners })),
          catchError(error => of(getPartnersFailureAction({ error }))),
        ),
      ),
    ),
  );

  getPartnerDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPartnerDetailsRequestAction),
      switchMap(({ id }) =>
        this.api.getPartner({ id }).pipe(
          map(partner => getPartnerDetailsSuccessAction({ partner })),
          catchError(error => of(getPartnerDetailsFailureAction({ error }))),
        ),
      ),
    ),
  );

  createPartner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createPartnerRequestAction),
      switchMap(({ partner: { name, note } }) =>
        this.api.createPartner({ body: { name, note } }).pipe(
          map(partner => createPartnerSuccessAction({ partner })),
          catchError(error => of(createPartnerFailureAction({ error }))),
        ),
      ),
    ),
  );

  partnerCreated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createPartnerSuccessAction),
      //TODO: redirect to the edit/detail view
      map(() => getPartnersRequestAction()),
    ),
  );

  deletePartner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deletePartnerRequestAction),
      switchMap(({ id }) =>
        this.api.deletePartner({ id }).pipe(
          map(() => deletePartnerSuccessAction({ id })),
          catchError(error => of(deletePartnerFailureAction({ error }))),
        ),
      ),
    ),
  );

  patchPartner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patchPartnerRequestAction),
      switchMap(({ partner: partnerData }) =>
        this.api.patchPartner({ id: partnerData.id, body: partnerData }).pipe(
          map(partner => patchPartnerSuccessAction({ partner })),
          catchError(error => of(patchPartnerFailureAction({ error }))),
        ),
      ),
    ),
  );

  patchSuccessModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(patchPartnerSuccessAction),
        tap(() => {
          this.snackBar.open(
            this.lang.partnerDetail.saveSuccessTitle,
            this.lang.common.close,
            { duration: SNACKBAR_DELAY },
          );
        }),
      ),
    { dispatch: false },
  );

  partnerPatched$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patchPartnerSuccessAction),
      //TODO: redirect?
      map(({ partner }) => getPartnerDetailsRequestAction({ id: partner.id })),
    ),
  );

  constructor(
    @Inject(TRANSLATION) public readonly lang: Translation,
    private readonly actions$: Actions,
    private readonly api: APIClient,
    private readonly snackBar: MatSnackBar,
  ) {}
}

import { createAction, props } from '@ngrx/store';
import { V2Template } from '../../../api/models';

export const getTemplatesRequestAction = createAction(
  'GetTemplatesRequestAction',
  props<{ integrationId: string }>(),
);

export const getTemplatesSuccessAction = createAction(
  'GetTemplatesSuccessAction',
  props<{ templates: V2Template[] }>(),
);

export const getTemplatesFailureAction = createAction(
  'GetTemplatesFailureAction',
  props<{ error: Error }>(),
);

export const getTemplateDetailRequestAction = createAction(
  'GetTemplateDetailRequestAction',
  props<{ id: string }>(),
);

export const getTemplateDetailSuccessAction = createAction(
  'GetTemplateDetailSuccessAction',
  props<{ template: V2Template }>(),
);

export const getTemplateDetailFailureAction = createAction(
  'GetTemplateDetailFailureAction',
  props<{ error: Error }>(),
);

export const patchTemplateRequestAction = createAction(
  'PatchTemplateRequestAction',
  props<{ template: V2Template }>(),
);

export const patchTemplateSuccessAction = createAction(
  'PatchTemplateSuccessAction',
  props<{ template: V2Template }>(),
);

export const patchTemplateFailureAction = createAction(
  'PatchTemplateFailureAction',
  props<{ error: Error }>(),
);

export const postTemplateRequestAction = createAction(
  'PostTemplateRequestAction',
  props<{ template: V2Template }>(),
);

export const postTemplateSuccessAction = createAction(
  'PostTemplateSuccessAction',
  props<{ template: V2Template }>(),
);

export const postTemplateFailureAction = createAction(
  'PostTemplateFailureAction',
  props<{ error: Error }>(),
);

export const deleteTemplateRequestAction = createAction(
  'DeleteTemplateRequestAction',
  props<{ templateId: string; integrationId: string }>(),
);

export const deleteTemplateSuccessAction = createAction(
  'DeleteTemplateSuccessAction',
  props<{ integrationId: string }>(),
);

export const deleteTemplateFailureAction = createAction(
  'DeleteTemplateFailureAction',
  props<{ error: Error }>(),
);
